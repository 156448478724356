import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import SocialMedia from '../social-media/socialmedia';

//style
import { FooterContainer } from './footer.css';
import { Row, Col } from 'reactstrap';
import { Container } from '../../global.css';

const Footer = ({ data, footerMenu }) => {
  const line = '|';
  const footerItem = data.pageMetadataJson.pages
    .filter(page => {
      return footerMenu.includes(page.url);
    })
    .sort((a, b) => {
      return footerMenu.indexOf(a.url) - footerMenu.indexOf(b.url);
    });
  return (
    <FooterContainer>
      <Container>
        <Row className="social-holder">
          <Col>
            <p>{data.footerJson.follow}</p>
            <SocialMedia />
          </Col>
        </Row>
        <Row className="nav-holder">
          <Col
            className={'menu-links'}
            lg={{ size: 6, order: 2 }}
            md={{ size: 12, order: 2 }}
            xs={{ size: 12, order: 1 }}
          >
            <div className="align-links">
              {footerItem.map((page, index) => (
                <div key={page.url + page.menuTitle}>
                  <Link to={`/${page.url}/`}>{page.menuTitle}</Link>
                  {index < footerItem.length - 1 ? line : null}
                </div>
              ))}
            </div>
          </Col>
          <Col
            className="aut-copy"
            lg={{ size: 3, order: 1 }}
            md={{ size: 12, order: 1 }}
            xs={{ size: 12, order: 3 }}
          >
            <p>{data.footerJson.copyRight}</p>
          </Col>
          <Col
            className={'powered'}
            lg={{ size: 12, order: 3 }}
            md={{ size: 12, order: 3 }}
            xs={{ size: 12, order: 2 }}
          >
            <div>
              <p> {data.footerJson.author}</p>
              <span className="d-flex">
                <img
                  src="/images/pharma_intelligence_logo.svg"
                  alt="Pharma Intelligence Logo"
                />
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

const FooterWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            mainMenu
            languages {
              defaultLangKey
              langs
              defaultPageUrl
            }
          }
        }
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
        footerJson {
          author
          copyRight
          follow
          poweredUrl
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);

export default FooterWithQuery;

Footer.propTypes = {
  data: PropTypes.any.isRequired,
  selectedLanguage: PropTypes.any.isRequired,
  footerMenu: PropTypes.array.isRequired,
};
