export const accent = '#f90000';
export const white = '#ffffff';
export const textColor = '#10384f';
export const subTitleColor = '#66b512';
export const linkBlue = '#0075a6';
export const textGrey = '#555555';
export const textActive = '#00617f';
export const purple = '#443247';

// sizes
// export const smallContainerWidth = '1140px';
// export const largeContainerWidth = '1440px';
export const containerWidth = '1140px';
export const containerMaxWidth = '100%';

// export const burgerMenu = 'url(/images/togglemenu.svg)';
