import styled from 'styled-components';

export const RedirectContent = styled.div`
  .redirect-modal-header {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid #e9ecef;
    .gatsby-image-wrapper {
      width: 210px;
      height: 42px;
    }
    svg {
      position: absolute;
      top: 12px;
      right: 6px;
      font-size: 35px;
      opacity: 0.5;
      :hover {
        opacity: 1;
      }
      :focus {
        outline: none;
      }
    }
  }
  .redirect-modal-content {
    color: #10384f;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    font-size: 14px;
    white-space: pre-line;
    padding: 16px;
    border-bottom: 1px solid #e9ecef;
  }
  .redirect-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    button,
    a {
      display: flex;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      color: #ffffff;
      position: relative;
      width: 164px;
      padding: 4px 8px 4px 10px;
      text-decoration: none;
      margin-bottom: 8px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-color: #00617f;
      border: solid 1px #00617f;
      span {
        display: flex;
        position: absolute;
        right: -1px;
        top: -1px;
        width: 31px;
        height: 31px;
        justify-content: center;
        align-items: center;
        background-color: #10384f;
        svg {
          width: 24px;
          height: 24px;
        }
        ::before {
          border-bottom: 31px solid #10384f;
          border-left: 14px solid transparent;
          content: '';
          position: absolute;
          left: -14px;
          top: 0;
        }
      }
      :hover {
        background-color: #0069d9;
        border: solid 1px #0069d9;
      }
      :focus {
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
      }
    }
    button {
      margin-right: 8px;
    }
  }
`;
