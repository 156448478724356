export const sortData = (dataToSort, masterData) => {
  const sortedData = {};

  dataToSort.filterItems.forEach(neededKey => {
    Object.keys(masterData || {}).forEach(key => {
      if ((key = neededKey.masterDataName) && masterData[key]) {
        sortedData[neededKey.masterDataName] = masterData[key].sort(
          (a, b) => a.SortOrder - b.SortOrder
        );
      } else {
        sortedData[neededKey.masterDataName] = [];
      }
    });
  });

  return sortedData;
};
