/* eslint-disable react/jsx-key */
import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import PropTypes from 'prop-types';
import { gMapApiKey, gApiLangCode } from '../../constants/config';
import { GoogleApiWrapper } from 'google-maps-react';
import { checkUserCountry } from '../map/map-utils';

class LocationSearchInput extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    changeValue: PropTypes.func,
    lat: PropTypes.any,
    lng: PropTypes.any,
    name: PropTypes.string,
    reset: PropTypes.bool,
    funcLoaded: PropTypes.func,
    funcMessage: PropTypes.func,
    showMessage: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  componentDidMount() {
    let { funcLoaded, name } = this.props;
    if (funcLoaded) {
      funcLoaded(true);
    }
    this.setState({ address: name });
  }
  componentDidUpdate() {
    if (this.props.reset === true && this.state.address !== '') {
      this.setState({ address: '' });
    }
  }

  handleChange = address => {
    const { changeValue, funcMessage } = this.props;
    if (address.length === 0) {
      changeValue('', '', '');
      funcMessage(true);
    } else {
      funcMessage(false);
    }
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    const { changeValue, funcMessage } = this.props;
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
    const request = {
      placeId,
      fields: ['geometry'],
    };
    placesService.getDetails(request, (place, status) => {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        this.setState({
          address: address,
        });
        changeValue(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          this.state.address
        );
      }
    });
    funcMessage(true);
  };

  render() {
    if (typeof window !== 'undefined' && window.google) {
      return (
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                maxLength="100"
                placeholder={this.props.placeholder}
                {...getInputProps({
                  className:
                    'location-search-input' +
                    (this.props.showMessage ? ' wrong' : ''),
                })}
              />
              {loading || suggestions.length ? (
                <div className="autocomplete-dropdown-container">
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#f9f9f9', cursor: 'pointer' }
                      : { backgroundColor: '#f9f9f9', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <li>{suggestion.description}</li>
                      </div>
                    );
                  })}
                  <div className="google-powered">
                    <img
                      src="/images/powered_by_google.png"
                      alt="Powered by Google Logo"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </PlacesAutocomplete>
      );
    }
    return (
      <div>
        <input
          placeholder={this.props.placeholder}
          className="location-search-input"
          disabled={true}
        />
      </div>
    );
  }
}

const LoadingContainer = placeholder => {
  return (
    <div>
      <input placeholder={placeholder} className="location-search-input" />
    </div>
  );
};

export default GoogleApiWrapper(props => {
  return {
    apiKey: gMapApiKey(),
    libraries: ['places'],
    language: gApiLangCode(),
    region: checkUserCountry() === 'CN' ? 'CN' : '',
    LoadingContainer: () => LoadingContainer(props.placeholder),
  };
})(LocationSearchInput);
