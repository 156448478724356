import { useEffect } from 'react';
import { DEV, UAT, PRD } from '../../constants/newrelic';

const useNewRelic = () => {
  useEffect(() => {
    const isDev =
      window.location.hostname.match(/dev/) ||
      window.location.hostname.match(/localhost/);
    const isUat = window.location.hostname.match(/uat/);
    const config = isDev ? DEV : isUat ? UAT : PRD;

    window.NREUM = {
      init: {
        distributed_tracing: { enabled: true },
        privacy: { cookies_enabled: true },
        ajax: { deny_list: ['bam.nr-data.net'] },
      },
      info: {
        beacon: 'bam.nr-data.net',
        errorBeacon: 'bam.nr-data.net',
        licenseKey: config.newrelicLicenseKey,
        applicationID: config.newrelicApplicationId,
        sa: 1,
      },
      loader_config: {
        applicationID: config.newrelicApplicationId,
        agentID: config.newrelicApplicationId,
        licenseKey: config.newrelicLicenseKey,
        accountID: config.newrelicAccountId,
        trustKey: config.newrelicTrustKey,
      },
    };
    const script = document.createElement('script');
    script.src = 'https://js-agent.newrelic.com/nr-loader-spa-1.260.1.min.js';
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useNewRelic;
