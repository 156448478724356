import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import AliceCarousel from 'react-alice-carousel';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { setRedirectUrl } from '../../constants/config';
import 'react-alice-carousel/lib/alice-carousel.css';
import { LanguageSelectorStyle, CustomArrows } from './language-selector.css';

const SelectLanguages = ({ languages, activeLang }) => {
  const refCarousel = useRef();

  const responsive = {
    0: { items: 4 },
    376: { items: 6 },
    550: { items: 8 },
    769: { items: 10 },
    992: { items: 12 },
  };

  return (
    <LanguageSelectorStyle>
      <AliceCarousel
        items={languages.map(lang => (
          <div key={lang.internalValue} className="lang-container">
            <a href={setRedirectUrl(lang.internalValue)} draggable={false}>
              <img
                src={lang.icon.publicURL}
                draggable={false}
                className={`${
                  activeLang === lang.internalValue
                    ? 'lang-icon active'
                    : 'lang-icon'
                }`}
                alt={lang.displayValue}
              />
              {lang.displayValue}
            </a>
          </div>
        ))}
        responsive={responsive}
        mouseTrackingEnabled={true}
        dotsDisabled={true}
        buttonsDisabled={true}
        keysControlDisabled={true}
        ref={refCarousel}
      />
      <CustomArrows>
        <button onClick={() => refCarousel.current.slidePrev()}>
          <IoIosArrowBack />
        </button>
        <button onClick={() => refCarousel.current.slideNext()}>
          <IoIosArrowForward />
        </button>
      </CustomArrows>
    </LanguageSelectorStyle>
  );
};

SelectLanguages.propTypes = {
  languages: PropTypes.array,
  setLanguage: PropTypes.func,
  activeLang: PropTypes.string,
};

export default SelectLanguages;
