import { createGlobalStyle } from 'styled-components';
import {
  accent,
  containerMaxWidth,
  linkBlue,
  subTitleColor,
  textColor,
  white,
} from 'constants/theme';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import HelveticaLTStd_Light_eot from './fonts/HelveticaLTStd-Light.eot';
import HelveticaLTStd_Light_ttf from './fonts/HelveticaLTStd-Light.ttf';
import HelveticaLTStd_Light_woff from './fonts/HelveticaLTStd-Light.woff';
import HelveticaLTStd_Bold_eot from './fonts/HelveticaLTStd-Bold.eot';
import HelveticaLTStd_Bold_ttf from './fonts/HelveticaLTStd-Bold.ttf';
import HelveticaLTStd_Bold_woff from './fonts/HelveticaLTStd-Bold.woff';
import HelveticaLTStd_BoldObl_eot from './fonts/HelveticaLTStd-BoldObl.eot';
import HelveticaLTStd_BoldObl_ttf from './fonts/HelveticaLTStd-BoldObl.ttf';
import HelveticaLTStd_BoldObl_woff from './fonts/HelveticaLTStd-BoldObl.woff';
import HelveticaLTStd_Roman_eot from './fonts/HelveticaLTStd-Roman.eot';
import HelveticaLTStd_Roman_ttf from './fonts/HelveticaLTStd-Roman.ttf';
import HelveticaLTStd_Roman_woff from './fonts/HelveticaLTStd-Roman.woff';
import BarlowCondensed_Bold_ttf from './fonts/BarlowCondensed-Bold.ttf';
import BarlowCondensed_Regular_ttf from './fonts/BarlowCondensed-Regular.ttf';
import FontAwesome_eot from './fonts/fontawesome-webfont.eot';
import FontAwesome_ttf from './fonts/fontawesome-webfont.ttf';
import FontAwesome_woff from './fonts/fontawesome-webfont.woff';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }

  @font-face {
    font-family: "BarlowCondensed";
    src:  url('${BarlowCondensed_Regular_ttf}') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "BarlowCondensed";
    src:  url('${BarlowCondensed_Bold_ttf}') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "BarlowCondensed";
    src:  url('${BarlowCondensed_Bold_ttf}') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaLTStd-light";
    src: url('${HelveticaLTStd_Light_eot}') format('embedded-opentype');
    src: url('${HelveticaLTStd_Light_woff}') format('woff'), url('${HelveticaLTStd_Light_ttf}') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaLTStd-Bold";
    src: url('${HelveticaLTStd_Bold_eot}') format('embedded-opentype');
    src: url('${HelveticaLTStd_Bold_woff}') format('woff'), url('${HelveticaLTStd_Bold_ttf}') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaLTStd-BoldObl";
    src: url('${HelveticaLTStd_BoldObl_eot}') format('embedded-opentype');
    src: url('${HelveticaLTStd_BoldObl_woff}') format('woff'), url('${HelveticaLTStd_BoldObl_ttf}') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: "Helvetica-LT-Std-roman";
    src: url('${HelveticaLTStd_Roman_eot}') format('embedded-opentype');
    src: url('${HelveticaLTStd_Roman_woff}') format('woff'), url('${HelveticaLTStd_Roman_ttf}') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
  font-family: 'FontAwesome';
  src: url('${FontAwesome_eot}') format('embedded-opentype');
  src:  url('${FontAwesome_woff}') format('woff'), url('${FontAwesome_ttf}') format('truetype');
  font-weight: normal;
  font-style: normal;
  }

  .addthis_toolbox{
    .email,
    .linkedIn {
      transition-duration: 0.2s;
      .at-icon-wrapper{
        background-color: #e52d27 !important;
        text-align: center;
      }
      svg{
        color: white;
        padding: 3.5px;
        width: 30px ;
        height: 30px ;
      }
      &:hover{
        transform: translateY(-4px);
      }
    }
    .linkedIn {
      .at-icon-wrapper {
        background-color: #0077b5 !important;
        svg {
          padding:2.5px;
        }
      }
    }
  }
  .fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  .fa-info-circle:before {
    content: "\f05a";
  }
  .fa-exclamation-circle:before {
    content: "\f06a";
  }
  .fa-chevron-left:before {
    content: "\f053";
  }
  .fa-chevron-right:before {
    content: "\f054";
  }
  body {
    font-family: "Helvetica-LT-Std-roman", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 10px;
    color: #000;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  .modal-open {
    padding-right: 17px;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${accent};
  }

  pre {
    display: block;
    padding: 20px;
    margin-top: 40px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${accent};
    border: 1px solid #ddd;
    font-family: "Helvetica-LT-Std-roman", "Helvetica Neue", Helvetica, sans-serif;
  }

  .website-container {
    width: 100%;
    max-width: ${containerMaxWidth};
    margin: 0 auto !important;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
  .tangram-suggestion-main {
    z-index: 5;
  }
  @media screen and (max-width: 492px){
    reach-portal + div {
      > div:nth-child(2) {
        transform: scale(0.85);
      }
    }
  }
  @media print {
    body {
      -webkit-print-color-adjust: exact;
    }
  }

 .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
 }
 @keyframes pulse {
   0%, 100% {
     opacity: 1;
   }
   50% {
     opacity: .5;
   }
 }
`;

export const Fonts = styled.div`
  .main-title {
    font-size: 48px;
    color: #10384f;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 500;
    margin: 0 0 28px;
    position: relative;
    padding: 0 0 10px;
    text-align: center;
    line-height: 1.2;
    ::after {
      width: 108px;
      height: 2px;
      background-color: #10384f;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -54px;
      content: ' ';
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 16px;
    color: ${textColor};
    font-weight: 400;
    text-align: left;
    line-height: 24px;
  }
  a {
    color: ${linkBlue};
    text-decoration: underline;
  }
  li {
    list-style-type: circle;
  }
  strong {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
    color: ${textColor};
    text-align: left;
    line-height: 24px;
  }
  a {
    color: ${linkBlue};
    text-decoration: underline;
  }
  li {
    list-style-type: circle;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 15px;
  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 960px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    max-width: 720px;
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    max-width: 540px;
  }
`;

export const HomePage = styled.div`
  display: flex;
  justify-content: center;
  .recruting-wrapper {
    margin-bottom: 10px;
    .row {
      justify-content: center;
    }
    h2 {
      padding-top: 40px;
    }
  }
  .filter-col {
    .filter-item:first-child {
      margin-top: 0;
    }
    .filter-item {
      margin-top: 16px;
    }
  }
  .banner-container {
    min-height: 422px;
    position: relative;
    margin-bottom: 70px;
    overflow: hidden;
  }
  .banner-left {
    width: 54%;
    position: absolute !important;
    height: 422px;
    left: 0;
    top: 0;
    background-size: cover;
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border-top: 242px solid rgba(109, 192, 35, 0.5);
      border-right: 103px solid transparent;
      z-index: 1;
    }
  }
  .banner-right {
    width: 46%;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    background: #715b72;
    padding: 50px 83px 30px 50px;
    display: flex;
    flex-direction: column;
    :before {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      border-bottom: 422px solid #715b72;
      border-left: 54px solid transparent;
    }
    h3 {
      color: #89d329;
      font-size: 48px;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
      line-height: 54px;
      margin-bottom: 15px;
      font-weight: 500;
      text-align: right;
      padding-top: 31px;
      white-space: pre-line;
    }
    p {
      color: #ffffff;
      font-size: 12px;
      line-height: 1.5;
      padding-top: 32px;
      margin-bottom: 16px;
    }
    .video-btn {
      position: relative;
      align-self: flex-end;
      width: max-content;
      margin: 0;
      background-color: #66b512;
      border: solid 1px #66b512;
      border-radius: 0;
      color: #ffffff;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 20px;
      line-height: 1.5;
      font-style: italic;
      font-weight: bold;
      text-transform: uppercase;
      padding: 6px 56px 6px 8px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      svg {
        width: 35px;
        height: 44px;
        background-color: #4a8c0f;
        position: absolute;
        right: -1px;
        top: -1px;
        font-size: 34px;
      }
      span {
        ::after {
          width: 0;
          height: 0;
          border-bottom: 43px solid #4a8c0f;
          border-left: 14px solid transparent;
          content: ' ';
          position: absolute;
          right: 34px;
          top: 0;
          margin-top: 0;
          display: block;
        }
      }
      :hover {
        background-color: #76cd19;
        border: solid 1px #76cd19;
      }
      :focus {
        outline: none;
      }
    }
    a {
      color: ${white};
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      width: fit-content;
      align-self: flex-end;
      text-transform: uppercase;
      text-decoration: none;
      margin: 24px 0 16px 0;
      &:hover {
        color: #0056b3;
        text-decoration: underline;
      }
      ::before {
        background-image: url(/images/white-single-line.png);
        content: ' ';
        width: 9px;
        height: 22px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
      }
    }
  }
  .banner-video {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .expect-section {
    margin-bottom: 28px;
    overflow: hidden;
  }
  .expect-img {
    min-height: 300px;
    height: 100%;
  }
  .expect-left {
    padding: 0;
  }
  .expect-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e1e1e1;
    ::before {
      position: absolute;
      right: 100%;
      top: 0;
      content: '';
      background: linear-gradient(
        to right bottom,
        transparent 49%,
        #e1e1e1 50%
      );
      width: 54px;
      height: 100%;
    }
    .expect-text,
    .patient-navigation-text {
      padding: 20px;
      background-color: #e1e1e1;
      p {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 16px;
      }
      a {
        color: #10384f;
        font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
          sans-serif;
        font-size: 14px;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        text-decoration: none;
        float: right;
        &:hover {
          color: #0056b3;
          text-decoration: underline;
        }
        ::before {
          background-image: url(/images/blue-single-line.png);
          content: ' ';
          width: 9px;
          height: 22px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
        }
      }
    }
  }
  .patient-navigation-text {
    p {
      &.patient-navigator-paragraph {
        margin-bottom: 40px;
        text-align: center;
      }
    }
  }
  .patient-navigator-logo {
    padding: 0;
    border: none;
    margin: 0;
    max-width: 250px;
    width: 100%;
    margin-top: 12px;
    cursor: pointer;
  }
  .about-text {
    margin-top: 20px;
    p {
      margin-bottom: 15px;
    }
  }
  .about-btn {
    text-align: right;
    margin-bottom: 30px;
    a {
      color: #10384f;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      text-transform: uppercase;
      text-decoration: none;
      margin-bottom: 30px;
      &:hover {
        color: #0056b3;
        text-decoration: underline;
      }
      ::before {
        background-image: url(/images/blue-single-line.png);
        content: ' ';
        width: 9px;
        height: 22px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .banner-right {
      padding: 50px 34px 50px 16px;
      .video-btn {
        font-size: 14px;
        span {
          svg {
            height: 35px;
          }
          ::after {
            border-bottom-width: 34px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .banner {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .banner-left {
      width: 100%;
      position: relative !important;
      height: 255px;
    }
    .banner-right {
      width: 100%;
      position: relative;
      min-height: auto;
      padding: 30px 28px 0 20px;
      :before {
        right: auto;
        bottom: 100%;
        border-right: 992px solid #715b72;
        border-top: 30px solid transparent;
        border-left: 0;
        border-bottom: 0;
        width: 0;
        height: 0;
        top: auto;
        left: 0;
      }
      h3 {
        font-size: 32px;
        padding-top: 15px;
        line-height: 1.2;
      }
      a {
        margin-top: 32px;
        margin-bottom: 1px;
        line-height: 16px;
        ::before {
          position: absolute;
          margin-right: 0;
          left: -18px;
          top: -4px;
        }
      }
    }
    .about-text {
      p {
        font-size: 16px;
      }
    }
    .main-title:nth-child(2),
    .main-title:nth-child(2) + div {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    .main-title {
      font-size: 32px;
      line-height: 38px;
    }
    .banner-col {
      padding: 0;
    }
    .banner-left {
      ::before {
        content: none;
      }
    }
    .banner-right {
      padding-right: 30px;
      a {
        margin-top: 20px;
        margin-bottom: 14px;
        ::before {
          position: absolute;
          margin-right: 0;
          left: -18px;
          top: -2px;
        }
      }
    }
    .expect-right {
      background-color: transparent;
      ::before {
        right: 15px;
        top: -40px;
        border-bottom: 40px solid #e1e1e1;
        border-left: 510px solid transparent;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .banner-col {
      padding: 0;
    }
  }
`;
export const ParticipatePage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .banner-container {
    min-height: 422px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 70px;
    overflow: hidden;
  }
  .banner-left {
    width: 54%;
    position: absolute !important;
    height: 422px;
    left: 0;
    top: 0;
    background-size: cover;
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border-top: 242px solid rgba(109, 192, 35, 0.5);
      border-right: 103px solid transparent;
      z-index: 1;
    }
  }

  .banner-right {
    width: 46%;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    background: #715b72;
    padding: 50px 83px 30px 50px;
    display: flex;
    flex-direction: column;
    :before {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      border-bottom: 422px solid #715b72;
      border-left: 50px solid transparent;
    }
    h3 {
      color: #89d329;
      font-size: 48px;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
      line-height: 54px;
      margin-bottom: 15px;
      font-weight: 500;
      text-align: right;
      padding-top: 35px;
      white-space: pre-line;
    }
    .video-btn {
      position: relative;
      align-self: flex-end;
      width: max-content;
      margin: 0;
      background-color: #66b512;
      border: solid 1px #66b512;
      border-radius: 0;
      color: #ffffff;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 20px;
      line-height: 1.5;
      font-style: italic;
      font-weight: bold;
      text-transform: uppercase;
      padding: 6px 56px 6px 8px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      svg {
        width: 35px;
        height: 44px;
        background-color: #4a8c0f;
        position: absolute;
        right: -1px;
        top: -1px;
        font-size: 34px;
      }
      span {
        ::after {
          width: 0;
          height: 0;
          border-bottom: 43px solid #4a8c0f;
          border-left: 14px solid transparent;
          content: ' ';
          position: absolute;
          right: 34px;
          top: 0;
          margin-top: 0;
          display: block;
        }
      }
      :hover {
        background-color: #76cd19;
        border: solid 1px #76cd19;
      }
      :focus {
        outline: none;
      }
    }
  }
  .banner-video {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  p {
    text-align: center;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
  }
  .benefit-img-desktop {
    margin-bottom: 40px;
  }
  .benefit-mobile-sec {
    display: none;
    h2 {
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 28px;
      color: #10384f;
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
    }
    .benefit-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      .benefit-img-tablet {
        width: 345px;
        height: 265px;
        img {
          position: relative;
        }
      }
      div,
      h3 {
        max-width: 86%;
      }
      h3 {
        padding: 15px 0 10px;
        font-size: 28px;
        line-height: 1.2;
        color: #10384f;
        font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
          sans-serif;
        text-align: center;
      }
      p {
        margin-bottom: 40px;
      }
    }
  }
  h4 {
    font-size: 34px;
    line-height: 1.2;
    padding: 0;
    color: #00617f;
    text-align: center;
    margin-bottom: 8px;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
  }
  .stories-text {
    margin-bottom: 56px;
    p {
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
    }
  }
  .stories-sec {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    div {
      position: relative;
      height: 230px;
      max-width: 430px;
      width: 100%;
    }
    .redirect-modal-btn {
      position: absolute;
      right: 30px;
      bottom: 20px;
      margin: 0;
      background-color: #66b512;
      border: solid 1px #66b512;
      border-radius: 0;
      color: #ffffff;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 20px;
      text-decoration: none;
      line-height: 1.5;
      font-style: italic;
      font-weight: bold;
      text-transform: uppercase;
      padding: 8px 72px 8px 8px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      span {
        width: 48px;
        height: calc(100% + 2px);
        background-color: #4a8c0f;
        position: absolute;
        right: -1px;
        top: -1px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          font-size: 32px;
        }
        ::after {
          width: 14px;
          height: 100%;
          background: linear-gradient(
            to right bottom,
            transparent 49%,
            #4a8c0f 50%
          );
          content: ' ';
          position: absolute;
          right: 47px;
          top: 0;
          margin-top: 0;
          display: block;
        }
      }
      :hover {
        background-color: #76cd19;
        border: solid 1px #76cd19;
      }
      :focus {
        outline: none;
      }
    }
  }
  .find-trial-text {
    padding: 0 15px;
    p {
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
    }
  }
  @media screen and (max-width: 1199px) {
    .banner-right {
      padding: 50px 34px 50px 16px;
      .video-btn {
        font-size: 14px;
        span {
          svg {
            height: 35px;
          }
          ::after {
            border-bottom-width: 34px;
          }
        }
      }
    }
    h4 {
      margin-right: 30px;
    }
    .stories-sec {
      div {
        margin-right: 30px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .banner {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .banner-left {
      width: 100%;
      position: relative !important;
      height: 255px;
    }
    .banner-right {
      width: 100%;
      position: relative;
      min-height: 220px;
      padding: 30px 25px;
      :before {
        right: auto;
        bottom: 100%;
        border-right: 992px solid #715b72;
        border-top: 30px solid transparent;
        border-left: 0;
        border-bottom: 0;
        width: 0;
        height: 0;
        top: auto;
        left: 0;
      }
      h3 {
        font-size: 32px;
        line-height: 1.2;
        padding-top: 15px;
      }
    }
    .stories-sec {
      margin-bottom: 60px;
    }
  }
  @media screen and (max-width: 767px) {
    .stories-sec {
      .redirect-modal-btn {
        max-width: calc(100% - 70px);
        word-break: break-word;
      }
    }
    .benefit-img-desktop {
      display: none;
    }
    .benefit-mobile-sec {
      display: flex;
    }

    .main-title {
      font-size: 32px;
      line-height: 1.2;
    }
    .row + .main-title {
      margin-bottom: 10px;
    }
    .main-title + div {
      p {
        margin-bottom: 20px;
      }
    }
    .benefit-mobile-sec + .main-title {
      margin-bottom: 28px;
    }
    .banner-left {
      ::before {
        display: none;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .banner-col {
      padding: 0;
    }
  }
  @media screen and (max-width: 545px) {
    .banner-col {
      padding: 0;
    }
    h4 {
      margin-right: 0;
    }
    .stories-sec {
      margin-bottom: 40px;
      div {
        margin-right: 0;
        padding: 0 15px;
        height: auto;
      }
      .stories-img {
        height: 100%;
        padding: 0;
        div {
          height: 100%;
          img {
            height: 100%;
          }
        }
      }
      .redirect-modal-btn {
        bottom: 10px;
        right: 40px;
      }
    }
  }
`;

export const WhatAreCTPage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 21px;
  .subtitle {
    p {
      text-align: center;
      margin-bottom: 16px;
      line-height: 1.5;
    }
  }
  .banner-container {
    display: flex;
    min-height: 422px;
    position: relative;
    margin-bottom: 40px;
    overflow: hidden;
  }
  .banner-left {
    width: 54%;
    position: absolute !important;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    div {
      display: flex;
      width: auto !important;
      height: auto;
      padding: 0 !important;
    }
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border-top: 242px solid rgba(109, 192, 35, 0.5);
      border-right: 103px solid transparent;
      z-index: 1;
    }
  }
  .banner-right {
    width: 46%;
    position: relative;
    margin-left: 54%;
    height: 100%;
    right: 0;
    top: 0;
    background: #715b72;
    padding: 30px 46px 30px 30px;
    display: flex;
    flex-direction: column;
    :before {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        to right bottom,
        transparent 49%,
        #715b72 50%
      );
      height: 100%;
      width: 50px;
    }
    h3 {
      color: #89d329;
      font-size: 48px;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
      line-height: 54px;
      margin-bottom: 15px;
      font-weight: 500;
      text-align: right;
      padding-top: 30px;
    }
    p {
      color: #ffffff;
      font-size: 12px;
      line-height: 1.5;
      padding-top: 32px;
      margin-bottom: 16px;
    }
    .video-btn {
      position: relative;
      align-self: flex-end;
      width: max-content;
      margin: 0;
      background-color: #66b512;
      border: solid 1px #66b512;
      border-radius: 0;
      color: #ffffff;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 20px;
      line-height: 1.5;
      font-style: italic;
      font-weight: bold;
      text-transform: uppercase;
      padding: 6px 56px 6px 8px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      svg {
        width: 35px;
        height: 44px;
        background-color: #4a8c0f;
        position: absolute;
        right: -1px;
        top: -1px;
        font-size: 34px;
      }
      span {
        ::after {
          width: 0;
          height: 0;
          border-bottom: 43px solid #4a8c0f;
          border-left: 14px solid transparent;
          content: ' ';
          position: absolute;
          right: 34px;
          top: 0;
          margin-top: 0;
          display: block;
        }
      }
      :hover {
        background-color: #76cd19;
        border: solid 1px #76cd19;
      }
      :focus {
        outline: none;
      }
    }
    @media screen and (max-width: 1199px) {
      @supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
        button {
          padding-bottom: 27px !important;
          line-height: 27px !important;
          top: -10px !important;
        }
      }
    }
  }
  .banner-video {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .phases-text {
    h2 {
      font-size: 48px;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-weight: 400;
      color: #10384f;
      margin-bottom: 15px;
      line-height: 1.2;
    }
    p {
      margin-bottom: 16px;
    }
  }
  .phases-box {
    margin-bottom: 15px;
    .nav-tabs {
      border-bottom: solid 1px #624963;
      justify-content: stretch;
      display: flex;
      flex-wrap: nowrap;
    }
    .nav-item {
      margin-right: 3px;
      list-style-type: none;
      .active {
        background-color: #ffffff;
        color: #834283;
        border-color: #624963 #624963 transparent;
      }
      :hover {
        color: #ffffff;
        cursor: pointer;
      }
    }
    .nav-link {
      height: 100%;
      word-break: break-word;
      border-radius: 0;
      padding: 14px 10px;
      font-size: 15px;
      line-height: 1.5;
      text-transform: uppercase;
      border: 1px solid transparent;
      background-color: #624963;
      color: #ffffff;
      @media screen and (min-width: 769px) {
        :focus,
        :hover {
          text-decoration: none;
          border-color: #e9ecef #e9ecef #dee2e6;
        }
        &.active {
          :hover,
          :focus {
            text-decoration: none;
            border-color: #624963 #624963 transparent;
          }
        }
      }
    }
    .tab-content {
      min-height: 290px;
      border: solid 1px #624963;
      border-top: 0;
      position: relative;
    }
    .fade {
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-duration: 0.15s;
    }
    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .phase-detail {
      display: flex;
      flex-direction: row;
      padding: 30px 30px 30px 0;
      div {
        padding-top: 5px;
        p {
          color: #999999;
          font-size: 18px;
          text-align: center;
          margin: 0;
          line-height: 1;
        }
        h4 {
          min-width: 138px;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 45px;
          line-height: 1;
          color: #999999;
          text-align: center;
        }
      }
      p {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
  .faq-title + .row {
    margin-bottom: 20px;
  }
  .find-trial-text {
    padding: 0 15px;
    p {
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
      line-height: 1.5;
    }
    p:last-child {
      margin-top: 0;
    }
  }
  @media screen and (min-width: 1080px) {
    .phases-box {
      .nav-tabs {
        .nav-item {
          &:first-child {
            max-width: 160px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 691px) {
    .phases-box {
      .nav-tabs {
        .nav-item {
          &:first-child {
            max-width: 160px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .banner-right {
      padding-right: 30px;
      h3 {
        padding-top: 0;
      }
      .video-btn {
        font-size: 14px;
        top: 0;
        svg {
          height: 35px;
        }
        span {
          ::after {
            border-bottom: 34px solid #4a8c0f;
          }
        }
      }
    }
    .phases-box {
      .tab-content {
        min-height: 240px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .banner {
      display: flex;
      justify-content: stretch;
      flex: 1 1 100%;
      .banner-right {
        min-height: 422px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .banner {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
    }
    .banner-left {
      width: 100%;
      position: relative !important;
      min-height: 255px;
    }
    .banner-right {
      width: 100%;
      margin-left: 0;
      min-height: 220px;
      padding: 30px 20px;
      :before {
        right: auto;
        bottom: 100%;
        border-right: 998px solid #715b72;
        border-top: 30px solid transparent;
        background: unset;
        border-left: 0;
        border-bottom: 0;
        width: 0;
        height: 0;
        top: auto;
        left: 0;
      }
      h3 {
        font-size: 32px;
        line-height: 1.2;
      }
      .video-btn {
        margin-bottom: 16px;
        @supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
          padding-bottom: 6px !important;
          line-height: 21px !important;
          top: 0 !important;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .main-title {
      font-size: 32px;
    }
    .banner-left {
      ::before {
        display: none;
      }
    }
    .phases-text {
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
    }
    .faq-title {
      margin: 15px 0 28px 0;
    }
    .find-trial-text {
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .phases-box {
      display: none;
    }
    .banner-container {
      margin: 0 -15px 40px -15px;
    }
  }
`;
export const WhatToExpectPage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .fix-padding {
    padding: 0 15px;
  }
  .main-title {
    margin: 5% 15% 4% 15%;
  }
  .research-staff {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 20px;
    align-items: center;
    p {
      width: 100%;
      text-align: center;
      margin: 0 0 6px 0;
    }
    #info-icon {
      color: #10384f;
      position: relative;
      left: 4px;
    }
    a {
      width: 48px;
      height: 48px;
      background-image: url('/images/pdf_new.png');
      img {
        cursor: pointer;
      }
    }
  }
  .step-section {
    max-width: 94%;
    margin: 0 auto;
    padding: 0 58px;
  }
  .step-article {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #e9e9e9 !important;
    min-height: 100px;
    text-align: center;
    border: 6px solid;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom: none;
    padding: 0 50px 0 40px;
    margin-bottom: 50px;
    max-width: 900px;
  }
  .item-0 {
    height: auto;
    border-color: #d30f4b;
    background-color: #d30f4b;
    ::before {
      color: #d30f4b;
    }
  }
  .item-1 {
    border-color: #624963;
    background-color: #624963;
    ::before {
      color: #624963;
    }
  }
  .item-2 {
    border-color: #00617f;
    background-color: #00617f;
    ::before {
      color: #00617f;
    }
  }
  .item-3 {
    border-color: #0091df;
    background-color: #0091df;
    ::before {
      color: #0091df;
    }
  }
  .item-4 {
    border-color: #2b6636;
    background-color: #2b6636;
    ::before {
      color: #2b6636;
    }
  }
  .item-5 {
    border-color: #66b512;
    background-color: #66b512;
    ::before {
      color: #66b512;
    }
  }
  .step-arrow {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 108px;
    height: 93px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    ::before {
      border-top: 30px solid;
      border-left: 54px solid transparent;
      border-right: 54px solid transparent;
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      top: 100%;
      width: 0;
    }
    strong {
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 36px;
      line-height: 1;
      color: #ffffff;
      margin: 0;
    }
  }
  .step-text {
    word-break: break-word;
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 10px 20px 10px 25px;
    text-align: left;
    color: #10384f;
    font-size: 18px;
    p {
      margin: 0;
    }
    h4 {
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
    }
  }
  .main-title + .row {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 1199px) and (min-width: 1101px) {
    .step-article {
      height: 140px !important;
    }
  }
  @media screen and (max-width: 1100px) {
    .step-text {
      padding-right: 10px;
    }
  }
  @media screen and (max-width: 991px) {
    .main-title {
      font-size: 32px;
      margin: 0;
    }
    .step-section {
      padding: 0 40px;
    }
    .step-article {
      margin: 44px 0;
    }
    .step-article:nth-child(odd) {
      padding-right: 10px;
    }
    .step-text {
      padding: 0 0 10px 15px;
      p,
      h4 {
        font-size: 16px !important;
      }
    }
    .find-trial-text {
      h2 {
        font-size: 32px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .step-section {
      max-width: 100%;
      padding: 0;
    }
    .step-article {
      padding: 0 0 0 20px;
    }
    .step-text {
      padding: 0 0 10px 50px;
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    .research-staff {
      margin-top: 15px;
      p {
        font-size: 16px;
      }
    }
    .step-section {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
    .step-article {
      padding: 0 0 0 20px;
    }
    .step-text {
      padding: 8px 0px 10px 25px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .fix-padding {
      padding: 0;
    }
    .step-text {
      padding: 8px 0px 10px 15px;
    }
  }
  @media screen and (max-width: 330px) {
    .step-article:nth-child(odd) {
      .step-text {
        max-width: 110px;
      }
    }
    .step-text {
      max-width: 120px;
    }
  }
`;

export const StyledTooltip = styled(UncontrolledTooltip)`
  .tooltip {
    opacity: 0.9;
  }
  .tooltip-inner {
    max-width: 200px;
    padding: 4px 8px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    background-color: #ffffff;
    color: #10384f;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    strong {
      font-weight: bold;
    }
    #qr-code {
      width: 150px;
      height: 150px;
    }
  }
  .bs-tooltip-auto {
    .arrow {
      ::before {
        border-top-color: #ffffff;
        border-bottom-color: #ffffff;
      }
    }
  }
  &.xl {
    .tooltip-inner {
      max-width: 600px;
    }
    @media (max-width: 600px) {
      .tooltip-inner {
        max-width: calc(100vw - 8px);
      }
    }
  }
  &.align-print-tooltip {
    .tooltip {
      .tooltip-inner {
        position: relative;
        top: -4px;
      }
      .arrow {
        height: 8px;
      }
    }
  }
  &.search-tooltips {
    .tooltip-inner {
      max-width: unset;
      width: 300px;
      padding: 20px;
      text-align: left;
    }
  }
  &.qr-tooltip {
    .tooltip-inner {
      max-width: 300px;
      padding: 10px;
      p {
        white-space: nowrap;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .tooltip {
      opacity: 0.95;
    }
  }
`;

export const AfterTrialSection = styled.div`
  .main-title {
    margin: 0 0 28px 0;
  }
  .card-container {
    height: 362px;
    width: 100%;
    margin-bottom: 30px;
    color: #ffffff;
  }
  .after-trial-card {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 1s;
  }
  .flip-card {
    transform: rotateY(180deg);
  }
  .front-card,
  .back-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 10px;
  }
  .front-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .back-card {
    transform: rotateY(180deg);
    p {
      color: #ffffff;
      text-align: left;
      padding: 15px;
      margin: 0;
      font-size: 17px;
      line-height: 24px;
    }
    a {
      color: #ffffff;
      font-style: italic;
      :hover {
        text-decoration: none;
      }
    }
  }
  /* front pane, placed above back */
  .front-card {
    z-index: 2;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  /* back, initially hidden pane */
  .back-card {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
  .card-icon {
    display: flex;
    min-height: 130px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    justify-content: center;
    pointer-events: none;
    div {
      display: flex;
      width: auto !important;
      height: auto;
      padding: 0 !important;
    }
    img {
      height: auto !important;
      width: auto !important;
      position: relative !important;
    }
  }
  .corner-icon {
    width: 24px;
    height: 28px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  h3 {
    font-size: 35px;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 300;
    text-align: center;
    color: #ffffff;
    line-height: 1.2;
    margin-bottom: 57px;
  }
  .card-0 {
    background-color: #de0043;
  }
  .card-1 {
    background-color: #00617f;
  }
  .card-2 {
    background-color: #108000;
  }
  .card-3 {
    background-color: #de0043;
  }
  @media screen and (max-width: 992px) {
    .main-title {
      font-size: 32px;
    }
    h3 {
      font-size: 28px;
    }
  }
  @media all and (-ms-high-contrast: none) {
    .after-trial-card {
      transition: transform 0s;
    }
    .back-card {
      transform: rotateY(0deg);
      display: none;
    }
    .flip-card {
      transform: rotateY(0deg);
    }
    .flipped {
      .back-card {
        z-index: 3;
        display: block;
      }
    }
  }
`;

export const TextPagesStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 15px;
  h1 {
    font-size: 48px;
    color: #10384f;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    margin: 0 0 28px;
    position: relative;
    padding: 0 0 10px;
    text-align: center;
    margin-bottom: 28px;
    line-height: 1.2;
    ::after {
      width: 108px;
      height: 2px;
      background-color: #10384f;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -54px;
      content: ' ';
    }
  }
  h2 {
    margin-top: 30px;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    color: ${subTitleColor};
    margin-bottom: 20px;
  }
  h2 + h1 {
    margin: 0;
  }
  h3 {
    text-align: center;
    font-size: 28px;
    line-height: 1.2;
    color: #10384f;
    margin-bottom: 8px;
  }
  h4 {
    color: #10384f;
    margin-bottom: 8px;
    strong {
      font-size: 16px;
      line-height: 1.2;
    }
  }
  ul,
  li {
    list-style-type: disc;
  }
  ul {
    padding-left: 40px;
    margin-bottom: 16px;
  }
  li {
    font-size: 16px;
    color: #10384f;
    text-align: left;
    line-height: 24px;
  }
  a {
    word-break: break-word;
  }
  h6 {
    padding-left: 50px;
    padding-top: 10px;
    color: #10384f;
    font-weight: bold;
    strong {
      font-size: 16px;
    }
  }
  h6 + p {
    padding-left: 50px;
    padding-bottom: 10px;
    font-size: 16px;
    margin-bottom: 46px;
  }
  strong {
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
  }
  span {
    color: red;
  }
  @media screen and (max-width: 991px) {
    h1 {
      padding: 0 15px 10px 15px;
    }
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
    p {
      font-size: 16px;
    }
    li {
      font-size: 14px;
    }
    strong {
      font-size: 16px;
    }
    h6 > strong,
    h6 + p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 420px) {
    h1 {
      font-size: 26px;
    }
  }
`;

export const SimpleSearch = styled.div`
  .fa-info-circle {
    position: relative;
    left: 5px;
    top: -5px;
  }
  .conditions-modal-btn {
    width: 100%;
    height: 48px;
    border: 1px solid #4a8c0f;
    font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    color: #10384f;
    padding: 6px 14px;
    text-align: left;
    position: relative;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    span {
      display: flex;
      justify-content: flex-start;
      position: absolute;
      right: 0;
      top: -1px;
      width: 44px;
      height: 48px;
      background-color: #4a8c0f;
      color: #ffffff;
      align-items: center;
      svg {
        margin-left: 5px;
        width: 26px;
        height: 26px;
      }
      ::before {
        width: 0;
        height: 0;
        border-bottom: 48px solid #4a8c0f;
        border-left: 14px solid transparent;
        content: ' ';
        position: absolute;
        left: -14px;
        top: 0;
        margin-top: 0;
        display: block;
      }
    }
    :focus {
      border-color: #76cd19;
      outline: 0;
      box-shadow: 0 0 0 1px rgba(109, 192, 35, 0.5);
    }
  }
  h3 {
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 4px;
    padding-left: 20px;
    color: #10384f;
    white-space: nowrap;
  }
  .general-form-input {
    .keyword-close-icon {
      right: 30px;
      bottom: 18px;
    }
    &:first-child {
      input {
        padding-right: 28px;
      }
    }
    p.warning-message {
      color: rgb(243, 51, 51);
      text-align: left;
      font-size: 14px;
      position: absolute;
      bottom: 0;
      margin-bottom: -25px;
    }
    input,
    .select-btn {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-appearance: none;
      width: 100%;
      height: 48px;
      background-color: #ffffff;
      border: 1px solid #488c0f;
      border-radius: 0;
      font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      color: #10384f;
      padding: 6px 14px;
      text-align: left;
      position: relative;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &.wrong {
        box-shadow: rgb(243, 51, 51) 0px 0px 4px 0px;
        border: 1px solid rgb(243, 51, 51);
        :focus {
          box-shadow: rgb(243, 51, 51) 0px 0px 4px 0px !important;
          border: 1px solid rgb(243, 51, 51);
        }
      }
      span {
        display: flex;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        top: -1px;
        width: 44px;
        height: 48px;
        background-color: #4a8c0f;
        color: #ffffff;
        align-items: center;
        svg {
          margin-left: 5px;
          width: 26px;
          height: 26px;
        }
        ::before {
          width: 0;
          height: 0;
          border-bottom: 48px solid #4a8c0f;
          border-left: 14px solid transparent;
          content: ' ';
          position: absolute;
          left: -14px;
          top: 0;
          margin-top: 0;
          display: block;
        }
      }
      :focus {
        box-shadow: 0 0 2px #76cd19;
        border-color: #76cd19;
        outline: 0;
      }
      &::placeholder {
        color: #10384f !important;
        opacity: 1;
      }
      :disabled {
        opacity: 0.4;
      }
    }

    .select-btn {
      &.active {
        span {
          display: none;
        }
      }
    }
  }
  .autocomplete-dropdown-container {
    max-height: 500px !important;
    .google-powered {
      display: flex;
      justify-content: flex-end;
      padding: 0 6px 6px 0;
    }
  }
  .autocomplete-dropdown-container,
  .suggestions {
    max-width: calc(100% - 30px);
  }
  .dropdown-menu,
  .autocomplete-dropdown-container,
  .suggestions {
    width: 100%;
    border-left: 1px solid #488c0f;
    border-right: 1px solid #488c0f;
    border-bottom: 1px solid #488c0f;
    border-top: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    max-height: 270px;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    margin-top: -2px;
    .dropdown-item,
    li {
      list-style-type: none;
      font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      line-height: 1.5;
      border-top: 1px solid #488c0f;
      font-weight: 400;
      background-color: #ffffff;
      padding: 6px 14px;
      color: #10384f;
      text-align: left;
      :hover {
        background-color: #e4e4e4;
      }
      &.no-match {
        pointer-events: none;
        font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
          sans-serif;
        font-weight: normal;
        span {
          font-style: italic;
        }
      }
    }
  }
  .check-box-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #4a8c0f;
    label {
      padding: 8px 16px;
      font-size: 12px;
      position: relative;
      width: 100%;
      border-bottom: 1px solid #d1d3d4;
      font-family: HelveticaLTStd-light;
      font-weight: 700;
      line-height: 1.5;
      color: #10384f;
    }
    input {
      position: absolute;
      top: 11px;
      right: 15px;
      opacity: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 11px;
      right: 15px;
      height: 15px;
      width: 15px;
      background-color: #ffffff;
      border: 1px solid #848181;
    }
    label:hover input ~ .checkmark {
      background-color: #10384f;
    }
    input:checked ~ .checkmark {
      background-color: #10384f;
    }
    input:focus ~ .checkmark {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
      left: 1px;
      top: 7px;
      background: #ffffff;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #ffffff, 4px 0 0 #ffffff, 4px -2px 0 #ffffff,
        4px -4px 0 #ffffff, 4px -6px 0 #ffffff, 4px -8px 0 #ffffff;
      transform: rotate(45deg);
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    @media (hover: none) {
      label:hover input ~ .checkmark {
        background-color: transparent;
      }
      input:checked ~ .checkmark {
        background-color: #10384f !important;
      }
    }
  }
  .search-col {
    display: flex;
    align-items: flex-end;
    .search-btn {
      border-radius: 0;
      background-color: #00617f;
      border: solid 1px #00617f;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      height: 48px;
      padding-right: 72px;
      position: relative;
      padding-left: 15px;
      color: #ffffff;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      span {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        width: 46px;
        height: calc(100% + 1px);
        background-color: #10384f;
        justify-content: center;
        align-items: center;
        svg {
          width: 26px;
          height: 26px;
        }
        ::before {
          width: 14px;
          height: 100%;
          background: linear-gradient(
            to right bottom,
            transparent 49%,
            #10384f 50%
          );
          content: ' ';
          position: absolute;
          left: -14px;
          top: 0;
          margin-top: 0;
          display: block;
        }
      }
      :focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
      }
      :hover {
        background-color: #0069d9;
        border: solid 1px #0069d9;
      }
    }
  }
  .show-more-col {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 16px;
    margin-top: 16px;
    .show-more-btn {
      margin-bottom: 30px;
      position: relative;
      background-color: transparent;
      color: #4a8c0f;
      border: 1px solid transparent;
      padding: 6px 20px;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      line-height: 1.5;
      text-transform: uppercase;
      :focus {
        outline: none;
        text-decoration: underline;
      }
      :hover {
        text-decoration: underline;
      }
      ::before {
        position: absolute;
        background: url(/images/two-line-green.png) center center no-repeat;
        width: 18px;
        height: 22px;
        content: '';
        left: 0;
      }
    }
  }
  .results-row {
    margin: 0 -16px 20px;
    align-items: flex-start;
    .autocomplete-dropdown-container,
    .suggestions {
      max-width: calc(100% - 10px);
    }
    .general-form-input {
      input,
      .select-btn {
        line-height: 21px;
        height: 48px;
        padding: 15px;
      }
      .select-btn {
        span {
          width: 46px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 24px;
            width: auto;
            height: auto;
            margin: 0;
          }
          ::before {
            border-bottom-width: 48px;
            border-left-width: 8px;
            left: -8px;
          }
        }
      }
      .dropdown-menu {
        button {
          font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
            sans-serif;
        }
      }
    }
    .postal-code-popover {
      h3 {
        display: inline-block;
      }
      span {
        font-size: 16px;
        color: #10384f;
        margin-left: 4px;
      }
    }
    .results-col-1,
    .results-col-2,
    .results-col-3,
    .results-col-4 {
      margin-bottom: 10px;
    }
    .results-col-2 {
      .autocomplete-dropdown-container {
        max-width: calc(100% - 30px);
      }
    }
    .results-col-4 {
      margin-top: 31px;
      @media screen and (max-width: 767px) {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
    h3 {
      padding: 0;
      margin-bottom: 1px;
    }
    .results-search-btn {
      min-width: 164px;
      width: 100%;
      text-align: left;
      height: 100%;
      padding: 15px 45px 15px 15px;
      position: relative;
      height: 48px;
      word-break: break-word;
      span {
        display: flex;
        position: absolute;
        right: -1px;
        top: -1px;
        width: 48px;
        height: calc(100% + 2px);
        justify-content: center;
        align-items: center;
        background-color: #10384f;
        svg {
          width: 24px;
          height: 24px;
        }
        ::before {
          height: 100%;
          width: 14px;
          background: linear-gradient(
            to right bottom,
            transparent 49%,
            #10384f 50%
          );
          content: ' ';
          position: absolute;
          left: -14px;
          top: 0;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .dropdown-menu {
      border-color: #488c0f;
      .dropdown-item {
        :focus {
          outline: 0;
          background-color: lightblue;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .results-row {
      margin-bottom: 0;
    }
    .results-col-4 {
      margin-top: 0;
      justify-content: flex-end;
    }
    .dropdown-menu,
    .autocomplete-dropdown-container,
    .suggestions {
      .dropdown-item,
      li {
        font-size: 16px !important;
      }
    }
    .general-form-input {
      input,
      .select-btn {
        font-size: 16px;
      }
      p.warning-message {
        color: rgb(243, 51, 51);
        text-align: left;
        font-size: 14px;
        position: relative;
        margin-bottom: 1px;
        margin-top: 3px;
      }
    }
  }
`;

export const ResultsPage = styled.div`
  margin: 30px 0 15px;
  display: flex;
  justify-content: center;
  .all-conditions-modal-btn {
    background: transparent;
    border: none;
    font-size: 14px;
    line-height: 18px;
    color: #275f7c;
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    padding-top: 8px;
    margin-bottom: 16px;
    span {
      display: none;
    }
  }
  .quick-search {
    margin-bottom: 10px;
  }
  .results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 18px;
      text-transform: uppercase;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
      color: #555555;
      margin-bottom: 15px;
    }
  }
  .results-row {
    label {
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      padding: 0 15px 10px;
      color: #10384f;
      white-space: nowrap;
      margin: 0;
    }
    h3 {
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      padding: 0 15px 10px;
      margin-bottom: 0;
    }
  }
  .filter-mobile {
    display: none;
  }

  .share-section {
    &.mobile {
      display: none;
      p {
        margin: 0 5px 0 0;
      }
      a,
      button {
        margin-right: 5px;
      }
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
    }
    display: flex;
    align-items: baseline;
    margin: 0 0 15px;
    &.desktop {
      p {
        margin-right: 5px;
        font-size: 16px;
      }
      .addthis_toolbox {
        a {
          padding: 0;
          margin: 0 2px 2px;
          width: 22px;
          height: 22px;
          .at-icon-wrapper {
            font-size: 22px;
            height: 22px !important;
            width: 22px !important;
          }
          svg {
            width: 22px !important;
            height: 22px !important;
            line-height: 22px;
            text-align: center;
            vertical-align: middle;
          }
        }
        .addthis_button_print {
          margin-left: 1px;
        }
        .addthis_button_wechat {
          margin-left: 1px;
        }
        .addthis_button_email {
          span {
            background: #e52d27 !important;
          }
        }
      }
    }
    .addthis_toolbox {
      display: flex;
      .at-svc-print {
        display: none;
      }
      .addthis_button_print,
      .addthis_button_wechat,
      .addthis_button_facebook,
      .addthis_button_twitter,
      .addthis_button_email {
        transition-timing-function: ease-out;
        transition-duration: 0.2s;
        svg {
          transform: translateX(1px);
        }
        :hover {
          transform: translateY(-4px);
        }
      }
    }
  }
  .share-section + div {
    margin-bottom: 30px;
  }
  .map-placeholder {
    margin-bottom: 40px;
  }
  .pagination-info {
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 18px;
    color: #10384f;
    margin-bottom: 30px;
  }
  .pagination-row {
    td {
      padding: 0;
      padding-top: 30px;
      border-top: 1px solid #d2d3d5;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    .page-item {
      list-style-type: none;
      .page-link {
        color: #a0a0a0;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
        border: 1px solid #f1f1f1;
        padding: 3px 9px;
        margin-left: -1px;
        font-size: 14px;
        line-height: 18px;
        text-decoration: none;
        &.active {
          background-color: #555555;
          color: #ffffff;
          z-index: 0;
        }
        :hover {
          background-color: #555555;
          color: #ffffff;
        }
      }
    }
    .page-item:first-child {
      .page-link {
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    .page-item:last-child {
      .page-link {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  .simple-pagination {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    .pagination-arrow {
      width: 40px;
      height: 33px;
      -webkit-appearance: none;
      background-color: #ffffff;
      border: 1px solid #f4f4f4;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      color: #10384f;
      span {
        font-size: 14px;
      }
      :focus {
        outline: none;
      }
      &.left {
        margin-right: 4px;
      }
    }
  }
  .hide-page {
    max-height: 700px;
    overflow: hidden;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .results-header {
      flex-direction: column-reverse;
      h3 {
        width: 100%;
      }
      .share-section {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .share-section {
      &.desktop {
        align-items: flex-end;
        flex-direction: column;
        p {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 60px;
    .filter-mobile {
      display: flex;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      p {
        font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
          sans-serif;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .share-section {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .addthis_button_print {
          width: 42px;
          height: 42px;
        }
        .addthis_button_wechat {
          width: 42px;
          height: 42px;
        }
      }
    }
  }
  @media screen and (max-width: 374px) {
    .share-section {
      &.mobile {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  @media print {
    .filter-desktop {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .filter-desktop + div {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
`;

export const ResultsTable = styled.div`
  .result-row {
    @media screen and (min-width: 768px) {
      .align-buttons {
        display: flex;
        flex-direction: column;
        align-items: end;
        a {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .result-row,
  .result-second-row {
    &.odd {
      background: #eee;
    }
  }
  .result-second-row {
    td {
      padding-top: 8px;
    }
  }
  th {
    text-align: left;
    min-height: 34px;
    background-color: #0091df;
    color: #ffffff;
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 16px;
    &:first-child {
      padding-left: 8px;
    }
    &:last-child {
      padding-right: 8px;
    }
    svg {
      margin-left: 4px;
    }
    &.table-head-1 {
      width: 20%;
    }
    &.table-head-2 {
      width: 20%;
    }
    &.table-head-3 {
      width: 12%;
      text-align: center;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
    &.table-head-4 {
      width: 18%;
    }
    &.table-head-5 {
      width: 29%;
    }
  }
  td {
    padding: 16px;
    vertical-align: top;
    font-size: 14px;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    line-height: 16px;
    color: #10384f;
    &:first-child {
      padding-left: 8px;
    }
    &:last-child {
      padding-right: 8px;
    }
  }
  .condition-section,
  .medical-condition-section {
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
  }
  .status-section {
    text-align: center;
    text-transform: capitalize;
    .status-icon {
      width: 44px;
      height: 44px;
      margin: 0 auto 5px;
      background: url('/images/sprite-2.png') no-repeat;
      background-position: -1px -230px;
      &.recstatus_study_completed {
        background-position: -1px -47px;
      }
      &.recstatus_recruiting {
        background-position: -1px -93px;
      }
      &.recstatus_not_yet_recruiting {
        background-position: -1px -139px;
      }
      &.recstatus_completed {
        background-position: -1px -185px;
      }
      &.recstatus_terminated {
        background-position: -1px -276px;
      }
    }
  }
  .requirements-section {
    display: flex;
    font-size: 24px;
    line-height: 46px;
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    p {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
    }
    .requirements-icon {
      display: flex;
      max-width: 46px;
      flex: 1 1 100%;
      height: 46px;
      margin-right: 2px;
      background: url('/images/icon-age-bar.png') no-repeat center center;

      &.gender_female {
        background: url('/images/icon-age-bar-female.png') no-repeat center
          center;
      }
      &.gender_male {
        background: url('/images/icon-age-bar-male.png') no-repeat center center;
      }
    }
  }
  .distance-section {
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    &.location-text {
      white-space: pre-wrap;
      font-size: 14px;
    }
    font-size: 14px;
  }
  .details-section {
    .links,
    .ask-to-join-modal-btn,
    .view-results-modal-btn {
      display: block;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      line-height: 18px;
      word-break: break-word;
      text-transform: uppercase;
      color: #ffffff;
      position: relative;
      width: 174px;
      height: fit-content;
      padding: 8px 46px 8px 15px;
      text-decoration: none;
      margin-bottom: 8px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-color: #66b512;
      border: solid 1px #66b512;
      span {
        display: flex;
        position: absolute;
        right: -1px;
        top: -1px;
        width: 31px;
        height: calc(100% + 2px);
        justify-content: center;
        align-items: center;
        background-color: #4a8c0f;
        svg {
          width: 24px;
          height: 24px;
        }
        ::before {
          background: linear-gradient(
            to right bottom,
            transparent 49%,
            #4a8c0f 50%
          );
          content: '';
          position: absolute;
          left: -14px;
          bottom: 0;
          height: 100%;
          width: 14px;
        }
      }
      :hover {
        background-color: #76cd19;
        border: solid 1px #76cd19;
      }
      :focus {
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
      }
      &.learn-more {
        background-color: #00617f;
        border: solid 1px #00617f;
        span {
          background-color: #10384f;
          ::before {
            background: linear-gradient(
              to right bottom,
              transparent 49%,
              #10384f 50%
            );
          }
        }
        :hover {
          background-color: #0069d9;
          border: solid 1px #0069d9;
        }
      }
    }
    .clamp-lines {
      display: none;
    }
    .view-results-modal-btn {
      background-color: #624963;
      border: solid 1px #624963;
      span {
        background-color: #443247;
        ::before {
          background: linear-gradient(
            to right bottom,
            transparent 49%,
            #443247 50%
          );
        }
      }
      :hover {
        background-color: #943398;
        border: solid 1px #943398;
      }
    }
  }
  .result-second-row {
    td {
      position: relative;
      .clamp-lines {
        position: relative;
        #clamped-content-text {
          padding-right: 20px;
        }
        button {
          position: absolute;
          right: -1px;
          top: -1px;
          width: 16px;
          height: 16px;
          padding: 0;
          border: none;
          background-color: transparent;
          font-size: 16px;
          color: #10384f;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          :hover {
            color: #00617f;
          }
          :focus {
            outline: none;
          }
        }
      }
    }
  }
  .no-results-message {
    font-size: 14px;
    line-height: 1.5;
    color: #10384f;
    p {
      display: inline;
      font-size: 14px;
      line-height: 1.5;
      a {
        color: #007bff;
        text-decoration: none;
        :hover {
          color: #0056b3;
          text-decoration: underline;
        }
      }
    }
  }
  @media print {
    .result-second-row {
      td {
        .clamp-lines {
          display: none;
        }
        [data-content]:before {
          content: attr(data-content);
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    th {
      &.table-head-1 {
        width: 19%;
      }
      &.table-head-2 {
        width: 19%;
      }
      &.table-head-3 {
        width: 12%;
      }
      &.table-head-4 {
        width: 13%;
      }
      &.table-head-5 {
        width: 17%;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .table-container {
      margin-right: -19px;
    }
    .result-second-row {
      td {
        .desktop-hide-icon {
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .table-container {
      margin: 0;
    }
    /* Pivot the table */
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }

    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
      border-bottom: 2px solid #333;
    }

    .responsiveTable tbody tr {
      border: 1px solid #000;
      padding: 0.25em;
    }

    .responsiveTable td.pivoted {
      /* Behave like a "row" */
      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }

    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;

      /* Top/left values mimic padding */
      left: 10px;
      width: calc(50% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
    }

    /* Style the pivoted table */
    .responsiveTable {
      .distance-section {
        &.pivoted {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .responsiveTable {
      .result-row {
        background: #f5f5f5;
        border: 1px solid #d1d3d4;
        margin-bottom: 34px;
      }
      .result-second-row {
        display: none;
      }
      .condition-section,
      .medical-condition-section,
      .status-section,
      .requirements-section,
      .distance-section {
        &.pivoted {
          border-bottom: 1px solid #d2d3d5 !important;
          .tdBefore {
            color: #0091df;
            font-size: 14px;
            svg {
              display: none;
            }
          }
        }
      }
      .status-section {
        &.pivoted {
          .status-icon {
            width: 30px;
            height: 30px;
            margin-left: 0;
            background-size: 30px auto;
            background-position: 0 -150px;
            &.recstatus_study_completed {
              background-position: 0 -30px;
            }
            &.recstatus_recruiting {
              background-position: 0 -60px;
            }
            &.recstatus_not_yet_recruiting {
              background-position: 0 -90px;
            }
            &.recstatus_completed {
              background-position: 0 -120px;
            }
            &.recstatus_terminated {
              background-position: 0 -180px;
            }
          }
        }
      }
      .requirements-section {
        &.pivoted {
          .tdBefore {
            line-height: 16px;
          }
        }
        display: flex;
        align-items: center;
        font-size: 20px;
        .requirements-icon {
          max-width: 34px;
          height: 46px;
          background-size: 34px auto;
          margin-right: 14px;
        }
      }
      .pivoted.details-section {
        padding-left: 8px !important;
        .clamp-lines {
          position: relative;
          margin-bottom: 10px;
          display: block;
          padding-right: 20px;
          button {
            position: absolute;
            right: -1px;
            top: -1px;
            width: 16px;
            height: 16px;
            padding: 0;
            border: none;
            background-color: transparent;
            font-size: 16px;
            color: #10384f;
            transition: color 0.15s ease-in-out,
              background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            :hover {
              color: #00617f;
            }
            :focus {
              outline: none;
            }
          }
        }
        .align-buttons {
          display: flex;
        }
        .links,
        .ask-to-join-modal-btn {
          width: 100%;
          margin: 0px 9px 8px;
        }
      }
      .pagination-row {
        border: none;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .responsiveTable {
      .pivoted.details-section {
        .align-buttons {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .learn-more,
          .ask-to-join-modal-btn,
          .view-results-modal-btn {
            max-width: unset;
            text-align: center;
            margin: 5px 9px;
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .responsiveTable {
      .pivoted.details-section {
        .align-buttons {
          .links,
          .ask-to-join-modal-btn {
            max-width: unset;
            text-align: center;
            margin: 5px 9px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) and (orientation: portrait) {
    .details-section {
      .view-results-modal-btn {
        width: 100%;
        margin: 5px 9px;
        text-align: center;
      }
    }
    .responsiveTable {
      .pivoted.details-section {
        .align-buttons {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

export const MapContainer = styled.div`
  > div {
    width: 100%;
    height: 400px;
    margin-bottom: 70px;
    page-break-inside: avoid;
  }
  .results-map {
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.25);
    @media print {
      max-width: 1110px;
      margin: 0 auto;
      .gm-style div > img {
        position: absolute;
      }
    }
  }
  strong {
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 12px;
    line-height: normal;
  }
  .marker-text {
    margin-bottom: 10px;
  }
  .marker-text:last-child {
    margin-bottom: 0;
  }
  .map-locations {
    height: 100%;
  }
`;

export const DetailsPage = styled.div`
  display: flex;
  justify-content: center;
  .fa-info-circle {
    margin: 0 4px;
  }
  .links,
  .ask-to-join-modal-btn,
  .additional-questions-modal-btn,
  .contact-us-modal-btn,
  .view-results-modal-btn,
  .study-cta-btn {
    display: block;
    font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
      sans-serif;
    font-size: 14px;
    line-height: 21px;
    word-break: break-word;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;
    width: 180px;
    padding: 4px 45px 4px 10px;
    text-decoration: none;
    margin-bottom: 8px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #66b512;
    border: solid 1px #66b512;
    span {
      display: flex;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 31px;
      height: calc(100% + 2px);
      justify-content: center;
      align-items: center;
      background-color: #4a8c0f;
      svg {
        width: 24px;
        height: 24px;
      }
      ::before {
        content: '';
        position: absolute;
        left: -14px;
        bottom: 0;
        width: 14px;
        height: 100%;
        background: linear-gradient(
          to right bottom,
          transparent 49%,
          #4a8c0f 50%
        );
      }
    }
    :hover {
      background-color: #76cd19;
      border: solid 1px #76cd19;
    }
    :focus {
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
      outline: none;
    }
    &.learn-more {
      background-color: #00617f;
      border: solid 1px #00617f;
      span {
        background-color: #10384f;
        ::before {
          background: linear-gradient(
            to right bottom,
            transparent 49%,
            #10384f 50%
          );
        }
      }
      :hover {
        background-color: #0069d9;
        border: solid 1px #0069d9;
      }
    }
  }
  .view-results-modal-btn {
    background-color: #624963;
    border: solid 1px #624963;
    word-break: break-word;
    span {
      background-color: #443247;
      svg {
        font-size: 12px;
        width: unset;
        height: unset;
        padding-bottom: 4px;
      }
      ::before {
        background: linear-gradient(
          to right bottom,
          transparent 49%,
          #443247 50%
        );
      }
    }
    :hover {
      background-color: #943398;
      border: solid 1px #943398;
    }
  }
  .button-holder {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    height: auto;
    button {
      background-color: #4a8c0f;
      width: auto;
      height: auto;
      right: 0;
      top: 0;
      padding: 5px 20px 0 20px;
      color: #ffffff;
      font-size: 16px;
      line-height: 1.5;
      border: 1px solid transparent;
      border-radius: 5px;
      text-transform: uppercase;
      :focus {
        outline: 0;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }
  .fixed-table-header {
    display: flex;
    background-color: #0091df;
    align-items: center;

    .locations-qualify-button {
      padding: 0px !important;
      margin-left: auto;

      div {
        padding: 0px !important;
        margin: 0px !important;

        & ~ button {
          display: none;
        }
      }

      a {
        margin-bottom: 0 !important;
        margin-right: 30px;
        padding: 4px 45px 4px 10px;
      }
    }

    div {
      padding: 8px;
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      vertical-align: middle;
      color: #ffffff;
      display: flex;
      align-items: center;
      .ask-to-join-modal-btn {
        margin: 0 30px 0 auto;
      }
      &.contact-details {
        p {
          font-size: 14px;
          line-height: 16px;
          text-align: left;
          color: #fff;
          margin: 0px;
        }
        span {
          font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
            sans-serif;
        }
      }
    }
    &.study-with-contact-details {
      div {
        align-items: flex-start;
      }
      > div:nth-child(2) {
        width: 17%;
      }
      > div:nth-child(3) {
        width: 17%;
      }
      > div:nth-child(4) {
        width: 21%;
      }
      &.align-status {
        > div:nth-child(1) {
          width: 45%;
        }
      }
      .ask-to-join-modal-btn {
        margin-right: 0px;
      }
    }
    > div:nth-child(1) {
      width: 50%;
    }
    > div:nth-child(2) {
      width: 20%;
    }
    > div:nth-child(3) {
      width: 30%;
    }
    &.align-status {
      > div:nth-child(1) {
        width: calc(50% - 10px);
      }
    }
  }
  @media screen and (max-width: 991px) {
    .fixed-table-header {
      > div:nth-child(1),
      > div:nth-child(2) {
        display: none;
      }
      > div:nth-child(3) {
        width: 100%;
        button {
          margin: 0 145px 0 auto;
        }
      }
      &.study-with-contact-details {
        > div:nth-child(1),
        > div:nth-child(2),
        > div:nth-child(3).contact-details {
          display: none !important;
        }
        > div:nth-child(4) {
          width: 100%;
          button {
            margin: 0 145px 0 auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .button-row {
      margin-bottom: 31px;
      border: 1px solid #d1d3d4;
      div {
        border-bottom: 1px solid #d2d3d5 !important;
        background-color: #f5f5f5;
      }
      div:first-child {
        height: 34px;
      }
      div:last-child {
        height: 49px;
      }
    }
    .button-holder {
      background-color: #f5f5f5;
    }
  }
  @media screen and (max-width: 767px) {
    .main-title {
      font-size: 32px;
      word-break: break-word;
    }
    .fixed-table-header {
      > div:nth-child(3) {
        button {
          margin: 0 auto;
        }
      }
    }
  }
  #cta-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #cta-button-container > div {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }
`;

export const DetailsHero = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #00617f;
  color: #ffffff;
  .go-back-button {
    width: fit-content;
    align-self: flex-start;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 16px;
    padding: 0 23px;
    :focus {
      outline: none;
    }
    :hover {
      text-decoration: underline;
      opacity: 0.9;
    }
    svg {
      position: relative;
      top: -2px;
      font-size: 21px;
      margin-right: 4px;
    }
  }
  .data-display-row {
    justify-content: center;
    padding: 0 15px;
  }
  .details-title {
    font-size: 36px;
    line-height: 1;
    text-align: center;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    p.title-conditions {
      margin-bottom: 5px;
      font-size: 36px;
      line-height: 1;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
    }
    h1 {
      font-size: 36px;
      line-height: 1;
      padding-bottom: 17px;
      margin-bottom: 15px;
      position: relative;
      ::after {
        position: absolute;
        width: 108px;
        height: 2px;
        background-color: #00d931;
        content: '';
        left: 50%;
        bottom: 0;
        margin-left: -54px;
      }
    }
    p {
      font-size: 18px;
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
    }
  }
  .id-section {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    div {
      flex: 0 0 100%;
      padding: 0 15px;
      text-align: center;
      @media screen and (min-width: 768px) {
        flex: 0 0 50%;
      }
      @media screen and (min-width: 960px) {
        flex: 0 0 25%;
      }
    }
  }
  h3 {
    font-size: 16px;
    line-height: 1.2;
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    svg {
      font-size: 15px;
      position: relative;
      left: 3px;
      top: -1.5px;
    }
  }
  p {
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    text-align: center;
    a {
      color: #ffffff;
      :hover {
        opacity: 0.9;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin: 0 -15px;
  }
  @media screen and (max-width: 767px) {
    .details-title {
      font-size: 32px;
      h2 {
        word-break: break-word;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .go-back-button {
      padding: 0;
      margin-bottom: 20px;
    }
  }
`;

export const ShareSectionWrapper = styled.div`
  .share-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 15px 0;
    p {
      margin: 0;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    .share-section {
      p {
        font-size: 16px;
      }
      .at-share-btn-elements {
        a {
          margin-right: 3px;
        }
      }
    }
  }
`;

export const TrialPurposeSection = styled.div`
  margin-top: 36px;

  .additional-questions-modal-btn {
    width: auto;
    background: transparent;
    border: 1px solid transparent;
    color: #0091df;
    text-decoration: underline;
    font-size: 16px;
    line-height: 28px;
    padding: 0;
    :hover {
      border: 1px solid transparent;
      background: transparent;
      text-decoration: none;
    }
    :focus {
      box-shadow: none;
    }
    span {
      display: none;
    }
  }
  .overall-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #10384f;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    font-size: 20px;
    line-height: 22px;
    .icon-container {
      display: flex;
      position: relative;
      .status-icon {
        width: 58px;
        height: 58px;
        background: url('/images/sprite-2-xl.png') no-repeat;
        background-size: 58px auto;
        background-position: 0 -296px;
        &.recstatus_study_completed {
          background-position: 0 -58px;
        }
        &.recstatus_recruiting {
          background-position: 0 -118px;
        }
        &.recstatus_not_yet_recruiting {
          background-position: 0 -178px;
        }
        &.recstatus_completed {
          background-position: 0 -237px;
        }
        &.recstatus_terminated {
          background-position: 0 -356px;
        }
      }
      span {
        position: absolute;
        right: -13px;
        top: -5px;
        color: #10384f;
      }
    }
  }
  p {
    font-size: 18px;
    line-height: 1.5;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    color: #10384f;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }

  .main-title {
    padding-bottom: 16px;
    margin: 20px 0 30px;
  }
  .brief-summary {
    white-space: pre-line;
    text-align: left;
  }
  .banner-container {
    display: flex;
    margin-bottom: 30px;
    margin-top: 52px;
    max-height: 500px;
    overflow: hidden;
    h3 {
      font-size: 31px;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
      text-align: center;
      margin: 0;
    }
    .banner-left {
      width: 42%;
      padding: 30px 15px;
      background-color: #6a4c72;
      position: relative;
      h3 {
        color: #ffffff;
        font-size: 30px;
        line-height: 36px;
      }
      .requirements-container {
        display: flex;
        margin-top: 100px;
        .gender,
        .age {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50%;
        }
        .gender {
          margin-right: 15px;
        }
        .age {
          margin-left: 15px;
        }
        h4 {
          color: #ffffff;
          text-align: center;
          padding-bottom: 22px;
          margin-bottom: 22px;
          font-size: 16px;
          text-transform: uppercase;
          font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
            sans-serif;
          position: relative;
          :after {
            height: 2px;
            width: 34px;
            margin-left: -17px;
            position: absolute;
            left: 50%;
            bottom: 0;
            content: ' ';
            background-color: #ffffff;
          }
        }
        .gender-icon {
          width: 72px;
          height: 72px;
          background: url('/images/gender-icon-xl.png') no-repeat center center;
          &.female {
            background: url('/images/gender-female-white.png') no-repeat center
              center;
          }
          &.male {
            background: url('/images/gender-male-white.png') no-repeat center
              center;
          }
        }
        .age-range {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          h3 {
            position: relative;
            color: #ffffff;
            font-size: 28px;
            font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
              sans-serif;
            text-align: center;
            @media screen and (max-width: 1200px) {
              font-size: 24px;
            }
          }
          .min {
            white-space: pre-line;
            margin-right: 40px;
            ::after {
              content: '-';
              position: absolute;
              right: -25px;
            }
          }
          .max {
            white-space: nowrap;
          }
        }
      }
      ::after {
        content: '';
        position: absolute;
        top: 0;
        right: -83px;
        z-index: 1;
        border-top: 500px solid #6a4c72;
        border-right: 84px solid transparent;
      }
    }
    .banner-right {
      display: flex;
      flex-direction: column;
      width: 58%;
      padding: 30px 15px 30px 99px;
      background-color: #e1e1e1;
      position: relative;
      justify-content: space-evenly;
      h3 {
        color: #10384f;
        margin-bottom: 20px;
      }
      #clamped-content-text,
      p {
        white-space: pre-line;
        color: #10384f;
        font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
          sans-serif;
        font-size: 14px;
        margin: 0;
      }
      .criteria-modal-btn {
        align-self: flex-end;
        color: #624963;
        font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
          sans-serif;
        font-size: 14px;
        position: relative;
        text-transform: uppercase;
        background-color: transparent;
        border: 0;
        margin: 10px 0 16px 0;
        :hover {
          color: #0056b3;
          text-decoration: underline;
        }
        :focus {
          outline: none;
        }
        ::before {
          background: url(/images/brown-single-line.png) no-repeat left center;
          content: '';
          width: 9px;
          height: 22px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
        }
        span {
          display: none;
        }
      }
      .criteria-modal-btn:last-child {
        margin-bottom: 0;
      }
    }
  }
  .criteria-print {
    display: none;
    max-width: 920px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4.8px;
    margin: 30px auto 30px auto;
    padding: 16px;
    page-break-inside: avoid;
    h5 {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 0;
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      color: #10384f;
    }
    pre + h5 {
      margin-top: 8px;
    }
    pre {
      white-space: pre-line !important;
      padding: 0;
      margin: 0;
      border: 0;
      color: #10384f;
      font-size: 14px;
      line-height: 1.5;
    }
  }
  @media screen and (max-width: 991px) {
    .banner-container {
      margin: 52px -15px 30px;
      .banner-left {
        .requirements-container {
          margin-top: 80px;
          .age-range {
            flex-direction: column;
            justify-content: center;
            h3 {
              font-size: 22px;
              line-height: 24px;
              white-space: nowrap;
              &.min {
                margin-bottom: 26px;
                margin-right: 0;
                ::after {
                  left: 50%;
                  right: auto;
                  bottom: -26px;
                }
              }
            }
          }
        }
        ::after {
          border-right-width: 54px;
          right: -53px;
          top: unset;
          bottom: 0;
        }
      }
      .banner-right {
        padding-left: 69px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .main-title + p {
      font-size: 16px;
    }
    .overall-section {
      margin-bottom: 30px;
    }
    .align-center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .banner-container {
      max-height: fit-content;
      flex-direction: column;
      margin: 52px 0 30px;
      .banner-left {
        width: 100%;
        h3 {
          line-height: 36px;
          word-break: break-word;
        }
        .requirements-container {
          margin-top: 30px;
          .gender {
            .gender-icon {
              height: 68px;
            }
          }
          .age-range {
            span {
              line-height: 1;
            }
          }
          h4 {
            line-height: 1.2;
          }
        }
        ::after {
          top: unset;
          right: unset;
          left: 0;
          bottom: -48px;
          border-top: 48px solid #6a4c72;
          border-right: 900px solid transparent;
        }
      }
      .banner-right {
        width: 100%;
        padding: 80px 15px 30px 15px;
        h3 {
          line-height: 37px;
        }
      }
    }
  }
  @media print {
    .criteria-print {
      display: block;
    }
  }
`;
export const TrialSummarySection = styled.div`
  .trial-summary-row {
    margin: 0;
    justify-content: space-between;
    .summary-col {
      padding: 0;
      flex: 0 0 28%;
      max-width: 28%;
    }
  }
  .trial-summary-card {
    min-height: 209px;
    border: solid 1px #66b512;
    border-radius: 0;
    margin-bottom: 30px;
    page-break-inside: avoid;
    .card-body {
      padding: 20px 15px;
      h6 {
        color: #66b512;
        font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
          sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        position: relative;
        margin: 0 0 20px;
        padding: 0 0 22px;
        text-transform: uppercase;
        white-space: pre-line !important;
        ::after {
          background-color: #10384f;
          height: 2px;
          width: 34px;
          margin-left: -17px;
          position: absolute;
          left: 50%;
          bottom: 0;
          content: '';
        }
        span {
          font-size: 16px;
          color: #10384f;
        }
      }
      .summary-details {
        font-size: 36px;
        color: #10384f;
        text-align: center;
        font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
          sans-serif;
        &.dates {
          font-size: 20px;
          .trial-dates-align {
            padding: 0 5px;
            div {
              display: flex;
              flex-direction: column;
            }
          }
        }
        &.phase {
          span {
            font-size: 16px;
            position: relative;
            bottom: 8px;
            left: 4px;
          }
        }
        &.product {
          font-size: 16px;
        }
        &.not_available {
          font-size: 36px;
        }
        &.healthyVol {
          p {
            font-size: 16px;
            text-align: center;
            font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
              sans-serif;
            line-height: 18px;
            margin: 0;
            white-space: pre-line;
          }
        }
        svg {
          font-size: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .trial-summary-card {
      min-height: 228px;
      .card-body h6 {
        white-space: pre-line;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .trial-summary-row {
      .summary-col {
        flex: auto;
        max-width: 100%;
      }
    }
    .trial-summary-card {
      min-height: 209px;
    }
  }
`;

export const DetailsTable = styled.div`
  max-height: 405px;
  overflow: auto;
  .table-row {
    background: #eee;
    page-break-inside: avoid;
  }
  .table-row:nth-child(even) {
    background-color: #ffffff;
  }
  thead {
    background-color: #0091df;
    color: #ffffff;
    display: none;
    th {
      padding: 8px;
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      vertical-align: middle;
      .ask-to-join-modal-btn {
        margin: 0 30px 0 auto;
      }
    }
    tr {
      > th:nth-child(1) {
        width: 50%;
      }
      > th:nth-child(2) {
        width: 20%;
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        width: 50%;
      }
    }

    td {
      padding: 8px;
      vertical-align: top;
      color: #10384f;
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      line-height: 16px;
    }
    .table-with-contact-details {
      .location {
        width: 46%;
      }
      .contact-details-cell {
        width: 37%;
        a[href^='tel:'] {
          color: inherit;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .location {
      padding: 20px 8px;
      p {
        font-size: 14px;
        line-height: 16px;
        margin: 0;
      }
      > p:nth-child(2) {
        font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
          sans-serif;
        margin-bottom: 9px;
      }
    }
    .status {
      display: flex;
      align-items: center;
      padding: 16px 8px 25px 8px;
      .status-icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background: url('/images/sprite-2.png') no-repeat;
        background-position: -1px -200px;
        background-size: 40px !important;
        &.locstatus_recruiting {
          background-position: -1px -80px;
        }
        &.locstatus_not_yet_rec {
          background-position: -1px -120px;
        }
        &.locstatus_active_not_rec {
          background-position: -1px -160px;
        }
        &.locstatus_completed {
          background-position: -1px -40px;
        }
        &.locstatus_terminated_withdrawn,
        &.locstatus_terminated {
          background-position: -1px -240px;
        }
      }
    }
    .print-contact {
      display: none !important;
      vertical-align: middle;
      p,
      strong {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 4px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    max-height: 700px;
    .responsiveTable table {
      table-layout: fixed;
    }
    /* Pivot the table */
    .responsiveTable table,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }

    .responsiveTable tbody tr {
      border: 1px solid #d1d3d4;
      padding: 0;
      .contact-details-cell,
      > td:first-child {
        width: 100%;
      }
      .hidden-details {
        display: none;
      }
    }

    .responsiveTable td.pivoted {
      /* Behave like a "row" */
      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }

    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;

      /* Top/left values mimic padding */
      left: 10px;
      width: calc(50% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
    }

    /* Style the pivoted table */
    .responsiveTable {
      tbody {
        .table-row:nth-child(even) {
          background-color: #eee;
        }
        .table-row:nth-child(odd) {
          background-color: #f5f5f5;
        }
        .table-row {
          td {
            border-bottom: 1px solid #d2d3d5 !important;
            .tdBefore {
              font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
                sans-serif;
              color: #0091df;
              .subtitle {
                font-family: 'HelveticaLTStd-light';
              }
            }
            &.status {
              .status-icon {
                width: 30px;
                height: 30px;
                margin: 0 auto 5px 0;
                background: url('/images/sprite-2.png') no-repeat;
                background-size: 30px auto !important;
                background-position: 0 -150px;
                &.locstatus_recruiting {
                  background-position: 0 -60px;
                }
                &.locstatus_not_yet_rec {
                  background-position: 0px -90px;
                }
                &.locstatus_active_not_rec {
                  background-position: 0 -120px;
                }
                &.locstatus_completed {
                  background-position: 0 -30px;
                }
                &.locstatus_terminated_withdrawn,
                &.locstatus_terminated {
                  background-position: 0 -180px;
                }
              }
            }
            &.contact {
              .align-right {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .responsiveTable {
      thead tr {
        > th:nth-child(3) {
          width: 100%;
          button {
            margin: 0 auto;
          }
        }
      }
      tbody tr td,
      tbody tr td.contact-details-cell {
        padding: 8px;
      }
    }
  }
  @media print {
    max-height: unset;
    tbody {
      .print-contact {
        display: table-cell !important;
      }
    }
  }
`;

export const TrialDesignSection = styled.div`
  margin: 30px 0;
  .main-title {
    span {
      font-size: 24px;
      position: relative;
      left: 10px;
      bottom: 5px;
    }
    &.small {
      font-size: 14px;
      line-height: 1.2;
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
      margin-bottom: 30px;
      margin-top: 60px;
      padding: 0;
      ::after {
        top: 30px;
      }
    }
  }
  .title {
    color: #10384f;
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    margin-bottom: 10px;
    svg {
      font-size: 20px;
      margin-left: 30px;
    }
  }
  .subtitle {
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #10384f;
    text-align: center;
    margin-bottom: 30px;
  }
  .green-container {
    border: 2px solid #66b512;
    padding: 10px 20px;
    min-height: 233px;
    margin-bottom: 31px;
    page-break-inside: avoid;
    .align-text {
      display: flex;
      p {
        width: 50%;
        height: 69px;
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
        padding: 8px 12px;
        #assignment {
          margin-right: 0;
        }
      }
      div {
        width: 50%;
        p {
          width: 100%;
        }
      }
    }
    &.right {
      p {
        height: 52px;
      }
    }
    &.outcome {
      min-height: auto;
      margin-bottom: 40px;
      padding: 24px 24px 8px;
      ul {
        margin-bottom: 40px;
        li {
          list-style-type: disc;
          white-space: pre-line;
          margin: 0 0 0px 16px;
          font-size: 17px;
          line-height: 24px;
          color: #10384f;
          div {
            padding-bottom: 8px;
            font-size: 14px;
            line-height: 18px;
            strong {
              font-size: 14px;
              line-height: 18px;
            }
            &.measure {
              strong {
                font-size: 16px;
                line-height: 20px;
              }
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  .additional-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    .redirect-modal-btn {
      --webkit-appearance: none;
      border: 0;
      font-size: 18px;
      line-height: 1.5;
      width: fit-content;
      color: #0075a6;
      text-align: center;
      margin-bottom: 16px;
      svg {
        display: none;
      }
      :focus {
        outline: none;
      }
      :hover {
        text-decoration: underline;
      }
    }
    p {
      text-align: center;
      color: #0075a6;
    }
  }
  @media screen and (max-width: 991px) {
    .subtitle + .row {
      margin-bottom: 15px;
    }
    .green-container {
      margin-bottom: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    .title {
      font-size: 32px;
      line-height: 38px;
      svg {
        font-size: 18px;
        margin-left: 15px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .green-container {
      padding: 15px;
      .align-text {
        p {
          width: 60%;
          padding: 8px 0;
        }
        p + p,
        p + div {
          width: 40%;
        }
      }
    }
  }
  @media print {
    .collapse {
      display: block;
    }
  }
`;

export const ContactPage = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  color: #10384f;
  .error-list {
    background-color: #fef5f1;
    ul {
      color: #8c2e0b;
      background: url(/images/message-24-error.png) no-repeat 8px 8px;
      border: 1px solid #ed541d;
      padding: 10px 10px 10px 50px;
      margin: 6px 0;
      li {
        list-style-type: disc;
        font-size: 18px;
        font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
          sans-serif;
        line-height: 24px;
        margin-left: 20px;
      }
    }
  }
  .msg-sended {
    color: #234600;
    background: url(/images/message-24-ok.png) no-repeat 8px 8px;
    border: 1px solid #be7;
    background-color: #f8fff0;
    padding: 10px 10px 10px 50px;
    margin: 6px 0;
    p {
      margin: 0;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
    }
  }
  h5 {
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.5;
    padding: 16px;
    border-bottom: 1px solid #e9ecef;
  }
  .subtitle {
    padding: 16px 16px 0;
  }
  @media screen and (max-width: 767px) {
    .error-list {
      ul {
        li {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
`;
export const ContactModal = styled.div`
  h5 {
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    text-align: center;
    position: relative;
    padding: 30px 30px 8px;
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 1.5;
    color: #10384f;
    ::after {
      width: 108px;
      height: 2px;
      background-color: #10384f;
      content: '';
      left: 50%;
      bottom: 0;
      margin-left: -54px;
      position: absolute;
    }
  }
  p {
    text-align: center;
    padding: 0 16px;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
    color: #10384f;
  }
  .study-title {
    padding-top: 16px;
  }
  .received-req {
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica, sans-serif;
    padding: 16px;
    margin-bottom: 16px;
  }
  .privacy-statement,
  .recaptcha-terms {
    text-align: left;
    padding: 0;
    p {
      font-size: 16px;
    }
    a {
      color: #00617f;
      font-weight: 600;
      text-decoration: none;
    }
  }
  .privacy-statement {
    margin: 10px 0 5px 0;
  }
  form {
    .row {
      h3 {
        font-size: 14px;
        line-height: 1.5;
        font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica,
          sans-serif;
      }
      p {
        font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica,
          sans-serif;
      }
      letter-spacing: -0.5px;
    }
  }
  svg {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    background-color: #10384f;
    color: #ffffff;
    z-index: 5;
    cursor: pointer;
    :focus {
      outline: none;
    }
  }
  @media screen and (max-width: 991px) {
    .recaptcha-terms {
      p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .textarea-col {
      margin-top: 16px;
    }
  }
`;

export const NotFoundPage = styled.div`
  display: flex;
  justify-content: center;
  color: #10384f;
  font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica, sans-serif;
  min-height: 67vh;
  margin-bottom: 150px;
  h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 40px 0 !important;
  }
  p {
    font-size: 18px;
    margin-bottom: 16px;
    color: rgb(16, 56, 79);
    font-weight: 400;
    text-align: center;
    line-height: 24px;
  }
  a {
    color: rgb(0, 117, 166);
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    min-height: 100px;
    h1.main-title {
      font-size: 32px;
    }
  }
`;

export const MapStyle = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 15px;
  h2.main-title {
    margin: 40px 0;
  }
  li {
    list-style: none;
    margin-bottom: 8px;
    a {
      text-decoration: none;
      font-size: 16px;
      :hover {
        color: #76cd18;
      }
    }
  }
`;

export const StudyNotFoundContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 67vh;
  flex-direction: column;
  h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 40px 0 !important;
    text-align: center;
    color: #cf4e20;
  }
  a {
    color: rgb(0, 117, 166);
    text-decoration: underline;
  }
  p {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
  }
  @media (max-width: 767px) {
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    min-height: 100px;
    h1.main-title {
      font-size: 32px;
    }
  }
`;
