import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { RedirectContent } from './redirect.css';
import { IoIosClose, IoIosArrowForward } from 'react-icons/io';
import Img from 'gatsby-image';

class Redirect extends React.Component {
  render() {
    let { data, url, hideModal } = this.props;
    return (
      <RedirectContent>
        <div className="redirect-modal-header">
          <Img fluid={data.redirectJson.logo.childImageSharp.fluid} />
          <IoIosClose onClick={() => hideModal()} tabIndex={0} />
        </div>
        <div className="redirect-modal-content">
          {data.redirectJson.content}
        </div>
        <div className="redirect-modal-footer">
          <button onClick={() => hideModal()}>
            {data.redirectJson.no}
            <span>
              <IoIosArrowForward />
            </span>
          </button>
          <a
            onClick={() => hideModal()}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.redirectJson.yes}
            <span>
              <IoIosArrowForward />
            </span>
          </a>
        </div>
      </RedirectContent>
    );
  }
}

Redirect.propTypes = {
  data: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  hideModal: PropTypes.func,
};

const RedirectQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        redirectJson {
          content
          yes
          no
          logo {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => <Redirect data={data} {...props} />}
  />
);

export default RedirectQuery;
