import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  StyledDialogContent,
  StyledDialogOverlay,
} from './modal.css';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { FaCaretRight, FaDownload } from 'react-icons/fa';
import VisuallyHidden from '@reach/visually-hidden';

import '@reach/dialog/styles.css';

// This component is here only to to showcase the
// React Context integration. No need to keep this if
// you don't require a Modal in your project.
export default class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
    this.clientWidth = null;
  }
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    if (this.props.autoOpen) {
      this.handleModal();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    if (key === 'Escape') {
      this.props.open && this.props.hideModal();
    }
  };

  disableScrolling(open) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (open && typeof window !== 'undefined' && window.innerHeight) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      this.clientWidth = null;
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';

      this.setState({ open: false });
    }
  }

  handleModal = () => {
    if (typeof document !== 'undefined' && document.body) {
      this.clientWidth = document.body.getBoundingClientRect();
    }
    this.setState({ open: !this.state.open });
  };

  render() {
    const {
      children,
      open,
      type,
      buttonText,
      showModal,
      hideModal,
      arrowRight,
    } = this.props;

    if (typeof document !== 'undefined' && document.body && this.clientWidth) {
      this.disableScrolling(open);
    }

    return (
      <>
        {buttonText ? (
          <Button
            className={`${type}-modal-btn`}
            type="button"
            onClick={() => {
              this.handleModal();
              showModal();
              if (this.props.trackAnalytics) {
                this.props.trackAnalytics();
              }
            }}
          >
            {buttonText}
            <span>
              {arrowRight ? (
                type === 'redirect' ? (
                  <FaCaretRight />
                ) : (
                  <IoIosArrowForward />
                )
              ) : type === 'view-results' ? (
                <FaDownload />
              ) : (
                <IoIosArrowDown />
              )}
            </span>
          </Button>
        ) : (
          undefined
        )}

        <StyledDialogOverlay
          className={type}
          isOpen={this.state.open && open}
          onDismiss={() => {
            if (type !== 'view-results') {
              this.handleModal();
              hideModal();
            }
          }}
        >
          <StyledDialogContent className={type}>
            <Close
              onClick={() => {
                this.handleModal();
                hideModal();
              }}
              className={type}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <span aria-hidden>×</span>
            </Close>
            {children}
          </StyledDialogContent>
        </StyledDialogOverlay>
      </>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  buttonText: PropTypes.string,
  type: PropTypes.string,
  autoOpen: PropTypes.bool,
  arrowRight: PropTypes.bool,
  trackAnalytics: PropTypes.func,
};
