import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Modal from '../modal/modal';
import { FaTimes } from 'react-icons/fa';
import { DisclaimerContainer } from './disclaimer.css';

const readDisclaimerState = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('disclaimerAccepted');
  }

  return null;
};

const setDisclaimerState = newValue => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('disclaimerAccepted', newValue);
  }
};

const DisclaimerModal = ({ data }) => {
  const [isModalVisible, setModalVisible] = useState(
    readDisclaimerState() !== 'true'
  );

  const showModal = value => {
    setModalVisible(value);
    setTimeout(() => {
      if (
        typeof window !== 'undefined' &&
        window.location &&
        window.location.hash &&
        document.querySelector(window.location.hash)
      ) {
        window.scrollTo(
          0,
          document.querySelector(`${window.location.hash}`).offsetTop
        );
      }
    }, 10);
  };

  const [fullText, setFullTextVisible] = useState(false);
  const showFullText = () => setFullTextVisible(!fullText);

  useLayoutEffect(() => {
    if (readDisclaimerState() === null) {
      setDisclaimerState(false);
    }
    showModal(readDisclaimerState() !== 'true');
  }, []);

  const disableModal = () => {
    setDisclaimerState(true);
  };

  return (
    <Modal
      autoOpen={isModalVisible}
      open={isModalVisible}
      type="disclaimer"
      hideModal={() => showModal(false)}
    >
      <DisclaimerContainer>
        <div className="disclaimer-body">
          <button className="close-button" onClick={() => showModal(false)}>
            <FaTimes />
          </button>
          <h3>{data.disclaimerEnJson.title}</h3>
          <div
            className={fullText ? 'disclaimer-text full' : 'disclaimer-text'}
            dangerouslySetInnerHTML={{
              __html: data.disclaimerEnJson.content.childMarkdownRemark.html,
            }}
          />
          <button onClick={showFullText}>
            {fullText
              ? data.disclaimerEnJson.readLess
              : data.disclaimerEnJson.readMore}
          </button>
        </div>
        <div className="disclaimer-footer">
          <button
            onClick={() => {
              disableModal();
              showModal(false);
            }}
          >
            {data.disclaimerEnJson.accept}
          </button>
          <a onClick={() => showModal(false)} href={data.disclaimerEnJson.url}>
            {data.disclaimerEnJson.decline}
          </a>
        </div>
      </DisclaimerContainer>
    </Modal>
  );
};

DisclaimerModal.propTypes = {
  data: PropTypes.object.isRequired,
};

const DisclaimerWithQuery = props => (
  <StaticQuery
    query={graphql`
      query DisclaimerQuery {
        disclaimerEnJson {
          title
          content {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          readMore
          readLess
          accept
          decline
          url
        }
      }
    `}
    render={data => <DisclaimerModal data={data} {...props} />}
  />
);

export default DisclaimerWithQuery;
