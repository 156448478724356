import styled from 'styled-components';
import { textActive, white } from '../../constants/theme';

export const HeaderStyle = styled.header`
  display: flex;
  .header-row {
    width: 100%;
    margin: 0;
    z-index: 9;
    box-shadow: 0 1px 3px rgba(1, 1, 1, 0.1);
    @media all and (-ms-hight-contrast) {
      height: 84px;
    }
    .logo-link {
      display: flex;
      align-items: center;
      height: 100%;
      position: absolute;
      height: 84px;
      z-index: 10;
      .header-logo {
        @media screen and (max-width: 450px) {
          width: 140px;
          height: 28px;
        }
        @media screen and (max-width: 1240px) and (min-width: 1024px) {
          width: 175px;
          height: 36px;
        }
        width: 230px;
        height: 47px;
      }
    }
  }
  .header-col {
    display: flex;
    max-width: 1295px;
    width: 100%;
    margin: 0 auto;
    flex-basis: 100%;
    flex-grow: 0;
  }

  .find-btn {
    position: absolute;
    right: 112px;
    .last {
      width: 152px;
      padding: 0;
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      background-color: #76cd18;
      border: none;
      white-space: nowrap;
      line-height: 14px;
      height: 84px;
      color: ${white};
      position: relative;
      &:before {
        width: 18px;
        height: 84px;
        border-bottom: 84px solid #76cd19;
        border-left: 17px solid transparent;
        content: ' ';
        position: absolute;
        left: -17px;
        bottom: 0;
        top: 0;
      }
      &:after {
        width: 18px;
        height: 84px;
        border-top: 84px solid #76cd19;
        border-right: 17px solid transparent;
        content: ' ';
        position: absolute;
        right: -17px;
        top: 0;
        margin-top: 0;
        bottom: 0;
      }
      .text-button {
        white-space: pre-wrap;
      }
      svg {
        position: absolute;
        right: 0;
        bottom: 12px;
        font-size: 24px;
      }
      &:hover {
        outline: none;
        color: ${textActive};
      }
      &:active {
        outline: none;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .lang-selector {
    display: flex;
    position: absolute;
    right: 0px;
    top: 27px;
    .lang-icon {
      width: 29px;
      height: 29px;
      border: 1px solid #e5e5e5;
      border-radius: 50%;
      object-fit: cover;
    }
    button {
      width: 80px;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      -webkit-appearance: none;
      background-color: transparent;
      border: 0;
      :focus {
        outline: none;
      }
    }
    div {
      position: relative;
      min-width: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
      color: #555555;
      white-space: nowrap;
      margin-left: 5px;
      svg {
        font-size: 24px;
        color: #cccccc;
        position: absolute;
        top: 10px;
        left: -2px;
      }
    }
    &.active {
      div {
        font-weight: bold;
        color: #00627f;
        svg {
          color: #00627f;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .header-col {
      max-width: 720px;
      padding: 0;
    }
    .find-btn {
      right: 70px;
    }
    .lang-selector {
      top: 0;
      button {
        width: fit-content;
        padding: 13px 12px;
        width: 100%;
        justify-content: flex-start;
      }
      .lang-icon {
        width: 18px;
        height: 18px;
      }
      div {
        line-height: 18px;
        color: #ffffff;
      }
      svg {
        display: none;
      }
      &.active {
        div {
          font-weight: normal;
          color: #ffffff;
        }
        button {
          ::after {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  @media screen and (min-width: 380px) and (max-width: 767px) {
    .header-row {
      .logo-link {
        .header-logo {
          margin-left: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .header-col {
      max-width: 540px;
    }
  }
  @media print {
    .header-col {
      max-width: unset;
    }
    .navbar {
      display: block;
      .navbar-toggler {
        display: none;
      }
      .navbar-collapse {
        display: flex;
        justify-content: center;
        height: 84px;
        align-items: center;
        ul {
          display: flex;
          flex-direction: row;
          li a {
            text-decoration: none;
          }
        }
      }
    }
  }
`;

export const FindTrialDropDown = styled.div`
  min-height: 216px;
  position: absolute;
  width: 100%;
  background-color: #76cd19;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  .search-container {
    width: 100%;
    max-width: 920px;
    padding: 30px 0;
  }
  form {
    .row {
      h3 {
        color: #ffffff;
        text-align: left;
        font-size: 14px;
        margin-top: 16px;
      }
      .conditions-modal-btn,
      .select-btn,
      input {
        ::placeholder {
          color: #10384f;
        }
      }
      .warning-message {
        position: relative !important;
        margin-top: 5px !important;
        margin-bottom: 1px !important;
      }
      .dropdown-menu {
        button {
          font-size: 14px;
        }
      }
      .check-box-container {
        background-color: #ffffff;
        label {
          text-align: left;
          font-size: 12px;
        }
      }
      .show-more-btn {
        margin-bottom: 0;
        color: white;
        ::before {
          background: url(/images/two-line.png) no-repeat center center;
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .search-container {
      max-width: 800px;
      padding: 30px 15px;
    }
  }
  @media screen and (max-width: 991px) {
    .search-container {
      max-width: 720px;
    }
  }
  @media screen and (max-width: 767px) {
    .search-container {
      max-width: 540px;
    }
    .show-more-col .show-more-btn {
      padding-right: 25px;
    }
  }
  @media screen and (max-width: 400px) {
    .show-more-col {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;
export const LangSelectorDropDown = styled.div`
  position: absolute;
  width: 100%;
  z-index: 4;
  background-color: #fbfbfb;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 991px) {
    top: 378px;
    z-index: 99;
  }
`;
