import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { NavItem } from 'reactstrap';
import Modal from '../modal/modal';
import Redirect from '../redirect/redirect';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
export const MenuItem = ({
  page,
  currentLocation,
  index,
  menuItems,
  isLangSelectorOpen,
  isTrialFinderOpen,
  onSubMenuOpen,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const onlyActives = currentLocation.slice(1, 3);
  const isActive = onlyActives.includes(page.url);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isLangSelectorOpen || isTrialFinderOpen) {
      setIsCollapseOpen(false);
    }
  }, [isLangSelectorOpen, isTrialFinderOpen]);

  useEffect(() => {
    if (isCollapseOpen) {
      onSubMenuOpen();
    }
  }, [isCollapseOpen]);

  const canonicalUrl = page.url.concat(!page.url.includes('#') ? '/' : '');

  return (
    <NavItem
      {...(page.relations && page.relations.length > 0
        ? { className: 'relative' }
        : undefined)}
    >
      {page.relations && page.relations.length > 0 ? (
        <>
          <button
            onClick={() => {
              setIsCollapseOpen(!isCollapseOpen);
            }}
            style={{ position: 'relative' }}
            className={`nav-link nav-menu-${index} with-dropdown`}
          >
            {page.menuTitle}
            {isCollapseOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </button>
          <div className={`sub-menu ${isCollapseOpen ? 'open' : ''}`}>
            {page.relations
              .map(relation => {
                const foundedItem = menuItems.find(
                  item => item.url === relation
                );
                return foundedItem;
              })
              .map((val, index) => (
                <div key={index} className="sub-menu-item">
                  {val.url.includes('http://') ||
                  val.url.includes('https://') ? (
                    <Modal
                      open={isModalOpen}
                      buttonText={val.menuTitle}
                      type="redirect"
                      showModal={showModal}
                      hideModal={hideModal}
                      arrowRight={true}
                    >
                      <Redirect hideModal={hideModal} url={val.url} />
                    </Modal>
                  ) : (
                    <Link
                      onClick={() => {
                        window.__navigatingToLink = true;
                      }}
                      to={`/${page.url}`}
                      className={'nav-sub-link'}
                    >
                      {val.menuTitle}
                    </Link>
                  )}
                  {val.caption ? (
                    <div className="caption">{val.caption}</div>
                  ) : null}
                </div>
              ))}
          </div>
        </>
      ) : page.url.includes('http://') || page.url.includes('https://') ? (
        <Modal
          open={isModalOpen}
          buttonText={page.menuTitle}
          type="redirect"
          showModal={showModal}
          hideModal={hideModal}
          arrowRight={true}
        >
          <Redirect hideModal={hideModal} url={page.url} />
        </Modal>
      ) : (
        <Link
          onClick={() => {
            window.__navigatingToLink = true;
          }}
          to={`/${canonicalUrl}`}
          className={
            `nav-link nav-menu-${index}` +
            (isActive ? ' active ' : '') +
            (page.url === 'trial-search' ? ' last' : '') +
            (page.url === 'transparency-policy' ? ' transparency-policy' : '')
          }
        >
          {page.menuTitle}
        </Link>
      )}
    </NavItem>
  );
};

MenuItem.propTypes = {
  page: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  currentLocation: PropTypes.array.isRequired,
  menuItems: PropTypes.array.isRequired,
  isLangSelectorOpen: PropTypes.bool.isRequired,
  isTrialFinderOpen: PropTypes.bool.isRequired,
  onSubMenuOpen: PropTypes.func.isRequired,
};
