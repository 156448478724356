/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse } from 'reactstrap';
import Checkbox from './checkbox';
import { trackAnalytics } from '../../../helpers/trackAnalytics';

const CheckboxGroup = ({
  group,
  groupName,
  groupLabel,
  positionInList,
  isSimple,
  isTitleToggleable = false,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
    trackAnalytics('FilterToggle', {
      FilterName: groupLabel,
      Toggle: isOpen ? 'off' : 'on',
      PositionInList: positionInList,
      CurrentValues: getValues(groupName, []),
    });
  };

  const getValues = (paramName, defaultValue) => {
    let searchParams;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }
    return (
      (searchParams &&
        searchParams.get(paramName) &&
        searchParams.get(paramName).split('~')) ||
      defaultValue
    );
  };

  const [isChildOpen, setIsChildOpen] = useState(group.map(() => true));
  const toggleChild = childIndex => {
    isChildOpen[childIndex] = !isChildOpen[childIndex];
    setIsChildOpen([...isChildOpen]);
  };

  return isSimple ? (
    <Col md={4} xs={12} {...(className ? { className } : undefined)}>
      <h3>{groupLabel}</h3>
      <div className="check-box-container">
        {group.map((item, index) => (
          <Checkbox
            key={index}
            label={item.DisplayValue}
            value={item.InternalValue}
            fieldName={groupName}
          />
        ))}
      </div>
    </Col>
  ) : (
    <Col sm={12} className="filters-group">
      <div onClick={() => isTitleToggleable && toggle()} className="filter-btn">
        {isTitleToggleable ? (
          <img
            src={isOpen ? '/images/down-minus.png' : '/images/up-plus.png'}
            alt={isOpen ? 'minus-icon' : 'plus-icon'}
          />
        ) : null}
        <h3 className="title">{groupLabel}</h3>
      </div>
      <Collapse isOpen={isOpen}>
        <div className={'check-box-container-parent'}>
          {groupName !== 'conditions'
            ? group.map((item, index) => (
                <div key={index} className="filter-align">
                  <div className="filter-btn">
                    <h3 className={item.InternalValue}>{item.DisplayValue}</h3>
                  </div>
                  <Checkbox
                    key={index}
                    value={item.InternalValue}
                    fieldName={groupName}
                  />
                </div>
              ))
            : group.map((item, childIndex) => (
                <div key={childIndex}>
                  <div className="filter-align">
                    <div
                      onClick={() => toggleChild(childIndex)}
                      className="filter-btn"
                    >
                      <img
                        src={
                          isChildOpen[childIndex]
                            ? '/images/down-minus.png'
                            : '/images/up-plus.png'
                        }
                        alt={
                          isChildOpen[childIndex] ? 'minus-icon' : 'plus-icon'
                        }
                      />
                      <h3>{item.DisplayValue}</h3>
                    </div>
                    <Checkbox
                      key={childIndex}
                      value={item.InternalValue}
                      fieldName={groupName}
                      childrenCond={
                        item.Children && item.Children.length
                          ? item.Children.map(child => child.InternalValue)
                          : null
                      }
                    />
                  </div>
                  <Collapse isOpen={isChildOpen[childIndex]}>
                    {item.Children && item.Children.length && (
                      <div className="check-box-container-children">
                        {item.Children.map((subCondition, subIndex) => (
                          <div key={subIndex} className="filter-align">
                            <div className="filter-btn">
                              <h3>{subCondition.DisplayValue}</h3>
                            </div>
                            <Checkbox
                              key={childIndex}
                              value={subCondition.InternalValue}
                              fieldName={groupName}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </Collapse>
                </div>
              ))}
        </div>
      </Collapse>
      <hr />
    </Col>
  );
};

CheckboxGroup.propTypes = {
  group: PropTypes.array,
  groupName: PropTypes.string,
  groupLabel: PropTypes.string,
  positionInList: PropTypes.number,
  isSimple: PropTypes.bool,
  isTitleToggleable: PropTypes.bool,
  className: PropTypes.string,
};

export default CheckboxGroup;
