import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { StaticQuery, graphql } from 'gatsby';
import { Formik, Form } from 'formik';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { sortData } from './search-service';
import CheckboxGroup from './checkbox/checkbox-group';
import KewSearch from '../autocomplete/keyword-autocomplete';
import LocationAutocomplete from '../autocomplete/location-autocomplete';
// import BaiduAutocomplete from '../autocomplete/baidu-location-autocomplete';
import { checkUserCountry } from '../map/map-utils';
import { MasterDataContext } from '../../store/masterDataContext';

//style
import { SimpleSearch, StyledTooltip } from '../../global.css';

class FindATrial extends React.Component {
  static contextType = MasterDataContext;

  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: null,
      SearchTerm: '',
      collapse: false,
      formInitialized: false,
      preselectedCountry: '',
      popoverOpen: false,
      dropdownOpen: false,
      searchCountry: '',
      countries: [],
      modalOpen: false,
      keySugestion: '',
      radius: '',
      showAlert: false,
      locationField: true,
    };

    this.searchParams = null;
    this.selectedConditions = [];
    this.healthOption = '';
    this.preselectedZip = '';
    this.checkboxData = {};
    this.dropdownMenuRef = React.createRef();

    this.resetForm = this.resetForm.bind(this);
    this.collapse = this.collapse.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  onKeyDown(event) {
    if (
      this.state.dropdownOpen &&
      event &&
      event.key &&
      /[a-z,A-Z]/.test(event.key)
    ) {
      let searchCountry = this.state.searchCountry;
      if (event.key.length === 1) {
        searchCountry = this.state.searchCountry + event.key;
      } else {
        if (event.which && event.which === 8 && searchCountry.length) {
          searchCountry = searchCountry.substring(0, searchCountry.length - 1);
        }
      }
      this.setState({
        searchCountry,
      });
    }
  }

  prevMasterData = null;

  componentDidUpdate() {
    if (this.context) {
      const { masterData } = this.context;

      if (masterData !== this.prevMasterData) {
        this.prevMasterData = masterData;
        let { data } = this.props;
        let keySugestion = [];

        this.checkboxData = sortData(data.trialSearchEnJson, masterData || {});
        // create keysearch suggestions
        if (
          typeof window !== 'undefined' &&
          masterData &&
          Array.isArray(masterData.CONDITION)
        ) {
          masterData.CONDITION.forEach(element => {
            keySugestion.push(element.DisplayValue);
            if (element.Children) {
              element.Children.forEach(subElement =>
                keySugestion.push(subElement.DisplayValue)
              );
            }
          });
          const uniqueSuggestions = keySugestion.filter(
            (suggestion, index, array) => array.indexOf(suggestion) === index
          );
          this.setState({
            keySugestion: uniqueSuggestions,
          });
        }

        if (this.selectedConditions && this.selectedConditions.length) {
          let selectedDisplayValues = [];
          if (masterData && Array.isArray(masterData.CONDITION)) {
            masterData.CONDITION.forEach(condition => {
              if (this.selectedConditions.includes(condition.InternalValue)) {
                selectedDisplayValues.push(condition.DisplayValue);
              }

              if (condition.Children && condition.Children.length) {
                condition.Children.forEach(condition => {
                  if (
                    this.selectedConditions.includes(condition.InternalValue)
                  ) {
                    selectedDisplayValues.push(condition.DisplayValue);
                  }
                });
              }
            });
          }
          this.setState({
            selectedOptions: selectedDisplayValues.join(', '),
            formInitialized: true,
          });
        } else {
          this.setState({
            formInitialized: true,
          });
        }
      }
    }
  }

  componentDidMount() {
    let { location, data } = this.props;
    let keySugestion = [];

    try {
      this.searchParams = new URL(location.href).searchParams;
    } catch (e) {
      this.searchParams = new URLSearchParams('');
    }

    if (
      this.searchParams.get('MileRadius') &&
      this.searchParams.get('Latitude').length > 1
    ) {
      let radius = this.searchParams.get('MileRadius');
      this.setState({
        radius: data.trialSearchEnJson.radius.options.filter(
          item => item.key === radius
        )[0].value,
      });
    }

    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    this.checkboxData = sortData(data.trialSearchEnJson, masterData || {});
    // create keysearch suggestions
    if (
      typeof window !== 'undefined' &&
      masterData &&
      Array.isArray(masterData.CONDITION)
    ) {
      masterData.CONDITION.forEach(element => {
        keySugestion.push(element.DisplayValue);
        if (element.Children) {
          element.Children.forEach(subElement =>
            keySugestion.push(subElement.DisplayValue)
          );
        }
      });
      const uniqueSuggestions = keySugestion.filter(
        (suggestion, index, array) => array.indexOf(suggestion) === index
      );
      this.setState({
        keySugestion: uniqueSuggestions,
      });
    }

    if (this.selectedConditions && this.selectedConditions.length) {
      let selectedDisplayValues = [];
      if (masterData && Array.isArray(masterData.CONDITION)) {
        masterData.CONDITION.forEach(condition => {
          if (this.selectedConditions.includes(condition.InternalValue)) {
            selectedDisplayValues.push(condition.DisplayValue);
          }

          if (condition.Children && condition.Children.length) {
            condition.Children.forEach(condition => {
              if (this.selectedConditions.includes(condition.InternalValue)) {
                selectedDisplayValues.push(condition.DisplayValue);
              }
            });
          }
        });
      }
      this.setState({
        selectedOptions: selectedDisplayValues.join(', '),
        formInitialized: true,
      });
    } else {
      this.setState({
        formInitialized: true,
      });
    }

    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      searchCountry: '',
    });
  }

  showModal() {
    this.setState({
      modalOpen: true,
    });
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }

  collapse() {
    this.setState({ collapse: !this.state.collapse });
  }

  resetForm() {
    if (
      typeof window !== 'undefined' &&
      typeof window.location !== 'undefined'
    ) {
      window.location.reload();
    } else {
      if (this.props.location && this.props.location.reload) {
        this.props.location.reload();
      }
    }
  }

  handleSubmit(values) {
    let { location, navigate, searchCallBack } = this.props,
      searchParams = null;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }

    let finalQuery = {};
    searchParams.forEach((value, key) => {
      if (key === 'Keyword') {
        finalQuery[key] = value.trim();
      } else {
        finalQuery[key] = value;
      }
    });
    Object.keys(values).forEach(key => {
      if (key === 'Keyword') {
        finalQuery[key] = values[key].trim();
      } else {
        finalQuery[key] = values[key];
      }
    });
    finalQuery.page = '0';
    finalQuery.SortField = 'Location_Distance';
    finalQuery.SortOrder = 'asc';
    navigate(
      `/studies/?${Object.keys(finalQuery)
        .map(
          k =>
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent(
              Array.isArray(finalQuery[k])
                ? finalQuery[k].join('~')
                : `${finalQuery[k]}`
            )
        )
        .join('&')}`,
      { replace: true }
    );
    if (searchCallBack && typeof searchCallBack === 'function') {
      setTimeout(() => {
        searchCallBack();
      });
    }
  }

  renderCheckboxGroup() {
    const { data } = this.props;
    const filtersLayout = [
      ['RECSTATUS'],
      ['AGERANGE', 'GENDER'],
      ['BIOMARKERS'],
    ];

    return filtersLayout.map((filters, index) => (
      <Col key={index} lg={4} className="filter-col">
        <Row>
          {filters.map((filter, index) =>
            this.checkboxData[filter] &&
            this.checkboxData[filter].length > 0 ? (
              <CheckboxGroup
                key={index}
                group={this.checkboxData[filter]}
                groupName={
                  data.trialSearchEnJson.filterItems[
                    Object.keys(this.checkboxData).indexOf(filter)
                  ].formikInitialValue
                }
                groupLabel={
                  data.trialSearchEnJson.filterItems[
                    Object.keys(this.checkboxData).indexOf(filter)
                  ].label
                }
                isSimple={true}
                className={'col-md-12 filter-item'}
              />
            ) : null
          )}
        </Row>
      </Col>
    ));
  }

  render() {
    let { data, inHeader = false, onResults = false } = this.props;

    return (
      <SimpleSearch>
        {this.state.formInitialized && (
          <Formik
            enableReinitialize={false}
            initialValues={
              onResults
                ? {
                    Keyword:
                      (this.searchParams && this.searchParams.get('Keyword')) ||
                      '',
                    Latitude:
                      (this.searchParams &&
                        this.searchParams.get('Latitude') &&
                        this.searchParams.get('Latitude').split('~')) ||
                      '',
                    Longitude:
                      (this.searchParams &&
                        this.searchParams.get('Longitude') &&
                        this.searchParams.get('Longitude').split('~')) ||
                      '',
                    LocationName:
                      (this.searchParams &&
                        this.searchParams.get('LocationName') &&
                        this.searchParams.get('LocationName').split('~')) ||
                      '',
                    MileRadius:
                      (this.searchParams &&
                        this.searchParams.get('MileRadius')) ||
                      '',
                  }
                : {
                    Keyword:
                      (this.searchParams && this.searchParams.get('Keyword')) ||
                      '',
                    gender:
                      (this.searchParams &&
                        this.searchParams.get('gender') &&
                        this.searchParams.get('gender').split('~')) ||
                      [],
                    ageRange:
                      (this.searchParams &&
                        this.searchParams.get('ageRange') &&
                        this.searchParams.get('ageRange').split('~')) ||
                      [],
                    biomarkers:
                      (this.searchParams &&
                        this.searchParams.get('biomarkers') &&
                        this.searchParams.get('biomarkers').split('~')) ||
                      [],
                    Status: (this.searchParams &&
                      this.searchParams.get('Status') &&
                      this.searchParams.get('Status').split('~')) || [
                      'RECSTATUS_RECRUITING',
                      'RECSTATUS_NOT_YET_RECRUITING',
                    ],
                    Latitude:
                      (this.searchParams &&
                        this.searchParams.get('Latitude') &&
                        this.searchParams.get('Latitude').split('~')) ||
                      '',
                    Longitude:
                      (this.searchParams &&
                        this.searchParams.get('Longitude') &&
                        this.searchParams.get('Longitude').split('~')) ||
                      '',
                    MileRadius:
                      (this.searchParams &&
                        this.searchParams.get('MileRadius')) ||
                      '',
                  }
            }
            onSubmit={(values, actions) => {
              if (this.state.locationField) {
                actions.setSubmitting(true);
                this.handleSubmit(values);
                actions.setSubmitting(false);

                setTimeout(() => {
                  this.props.pageSearch ? this.props.pageSearch() : null;
                }, 1000);
              } else {
                this.setState({
                  showAlert: true,
                });
              }
            }}
            render={({
              handleChange,
              setFieldValue,
              values,
              handleSubmit: formikHandleSumit,
            }) => (
              <Form
                onReset={this.resetForm}
                onKeyDown={e => {
                  var key = e.charCode || e.keyCode || 0;
                  if (key == 13) {
                    e.preventDefault();
                    formikHandleSumit(values);
                  }
                }}
              >
                <Row className={onResults ? 'results-row' : ''}>
                  <Col
                    className={
                      onResults
                        ? 'general-form-input results-col-2'
                        : 'general-form-input'
                    }
                    lg={onResults ? 3 : 4}
                    md={onResults ? 6 : 4}
                    sm={12}
                  >
                    <h3>
                      {data.trialSearchEnJson.keywordLabel}
                      <span className="fa fa-info-circle" id="keyword" />
                    </h3>
                    <KewSearch
                      placeholder={data.trialSearchEnJson.keywordPH}
                      suggestions={this.state.keySugestion}
                      name="Keyword"
                      changeValue={value => {
                        setFieldValue('Keyword', value);
                      }}
                      userInput={values.Keyword}
                    />
                  </Col>
                  <StyledTooltip
                    trigger="hover"
                    placement="top"
                    target="keyword"
                    className="search-tooltips"
                  >
                    {data.trialSearchEnJson.keyowrdTooltip}
                  </StyledTooltip>
                  <Col
                    className={
                      onResults
                        ? 'general-form-input results-col-2'
                        : 'general-form-input'
                    }
                    lg={inHeader ? 4 : 3}
                    md={inHeader ? 4 : 6}
                    sm={12}
                  >
                    <h3>
                      {data.trialSearchEnJson.locationLabel}
                      <span className="fa fa-info-circle" id="location" />
                    </h3>

                    {checkUserCountry()
                      .toLocaleLowerCase()
                      .includes('cn') ? (
                      // <BaiduAutocomplete
                      //   placeholder={data.trialSearchEnJson.locationPH}
                      //   name={values.LocationName}
                      //   changeValue={(lat, long, name) => {
                      //     setFieldValue(['Latitude'], lat);
                      //     setFieldValue(['Longitude'], long);
                      //     setFieldValue(['LocationName'], name);
                      //     setFieldValue('MileRadius', '100');
                      //     const radius = data.trialSearchEnJson.radius.options.find(
                      //       item => item.key === '100'
                      //     );
                      //     this.setState({
                      //       radius: radius.value,
                      //     });
                      //     if (lat.length === 0 && long.length === 0) {
                      //       setFieldValue('MileRadius', '');
                      //       this.setState({
                      //         radius: '',
                      //       });
                      //     }
                      //   }}
                      //   showMessage={this.state.showAlert}
                      //   funcMessage={param => {
                      //     if (param === false) {
                      //       this.setState({
                      //         locationField: param,
                      //       });
                      //     } else {
                      //       this.setState({
                      //         locationField: true,
                      //         showAlert: false,
                      //       });
                      //     }
                      //   }}
                      // />
                      <div>
                        <input
                          placeholder={data.trialSearchEnJson.locationPH}
                          className="location-search-input"
                        />
                      </div>
                    ) : (
                      <LocationAutocomplete
                        placeholder={data.trialSearchEnJson.locationPH}
                        lat={values.Latitude}
                        lng={values.Longitude}
                        name={values.LocationName}
                        changeValue={(lat, long, name) => {
                          setFieldValue(['Latitude'], lat);
                          setFieldValue(['Longitude'], long);
                          setFieldValue('LocationName', name);
                          setFieldValue('MileRadius', '100');
                          const radius = data.trialSearchEnJson.radius.options.find(
                            item => item.key === '100'
                          );
                          this.setState({
                            radius: radius.value,
                          });
                          if (lat.length === 0 && long.length === 0) {
                            setFieldValue('MileRadius', '');
                            this.setState({
                              radius: '',
                            });
                          }
                        }}
                        showMessage={this.state.showAlert}
                        funcMessage={param => {
                          if (param === false) {
                            this.setState({
                              locationField: param,
                            });
                          } else {
                            this.setState({
                              locationField: true,
                              showAlert: false,
                            });
                          }
                        }}
                      />
                    )}
                    {this.state.showAlert ? (
                      <p className={'warning-message'}>
                        {data.trialSearchEnJson.locationError}
                      </p>
                    ) : null}
                  </Col>
                  <StyledTooltip
                    trigger="hover"
                    placement="top"
                    target="location"
                    className="search-tooltips"
                  >
                    {data.trialSearchEnJson.locationTooltip}
                  </StyledTooltip>
                  <Col
                    className={
                      onResults
                        ? 'general-form-input results-col-3'
                        : 'general-form-input'
                    }
                    lg={inHeader ? 4 : 3}
                    md={inHeader ? 4 : 6}
                    sm={12}
                  >
                    <h3>{data.trialSearchEnJson.distanceLabel}</h3>
                    <Dropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleDropdown.bind(this)}
                    >
                      <DropdownToggle
                        className={'select-btn'}
                        tag="button"
                        type="button"
                        name="MileRadius"
                        disabled={
                          values.Latitude.length < 1 &&
                          values.Longitude.length < 1
                        }
                        onChange={ev => {
                          handleChange(ev);
                          if (ev.target.value.length === 0) setFieldValue('');
                        }}
                      >
                        {this.state.radius
                          ? this.state.radius
                          : data.trialSearchEnJson.distanceLabel}
                        <span>
                          <IoIosArrowDown />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu flip={false} direction="down">
                        {data.trialSearchEnJson.radius.options.map(miles => (
                          <DropdownItem
                            onClick={ev => {
                              this.setState({
                                radius: `${miles.value}`,
                              });
                              handleChange(ev);
                              setFieldValue('MileRadius', `${miles.key}`);
                              setFieldValue('');
                            }}
                            key={miles.value}
                            value={miles.key}
                          >
                            {miles.value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  {inHeader ? (
                    <Col
                      sm={12}
                      className="show-more-col search-col"
                      hidden={this.state.collapse}
                    >
                      <button
                        className={'show-more-btn'}
                        type="button"
                        onClick={this.collapse}
                      >
                        {this.state.collapse
                          ? data.trialSearchEnJson.closeDropDown
                          : data.trialSearchEnJson.openDropDown}
                      </button>
                      <button className="search-btn" type="submit">
                        {data.trialSearchEnJson.search}
                        <span>
                          <IoIosArrowForward />
                        </span>
                      </button>
                    </Col>
                  ) : (
                    <Col
                      lg={onResults ? 3 : 2}
                      md={6}
                      sm={12}
                      className={
                        onResults ? 'search-col results-col-4' : 'search-col'
                      }
                    >
                      <button
                        className={
                          onResults
                            ? 'search-btn results-search-btn'
                            : 'search-btn'
                        }
                        type="submit"
                      >
                        {onResults
                          ? data.trialSearchEnJson.searchTrials
                          : data.trialSearchEnJson.search}
                        <span>
                          <IoIosArrowForward />
                        </span>
                      </button>
                    </Col>
                  )}
                  {!onResults && !inHeader && (
                    <Col className={'show-more-col'} sm={12}>
                      <button
                        className={'show-more-btn'}
                        hidden={this.state.collapse}
                        type="button"
                        onClick={this.collapse}
                      >
                        {this.state.collapse
                          ? data.trialSearchEnJson.closeDropDown
                          : data.trialSearchEnJson.openDropDown}
                      </button>
                    </Col>
                  )}
                  {!onResults && (
                    <Col>
                      <div hidden={!this.state.collapse}>
                        <Row>
                          {this.renderCheckboxGroup()}
                          {inHeader ? (
                            <Col sm={12} className="show-more-col search-col">
                              <button
                                className={'show-more-btn'}
                                hidden={!this.state.collapse}
                                type="button"
                                onClick={this.collapse}
                              >
                                {this.state.collapse
                                  ? data.trialSearchEnJson.closeDropDown
                                  : data.trialSearchEnJson.openDropDown}
                              </button>
                              <button
                                hidden={!this.state.collapse}
                                className={'search-btn'}
                                type="submit"
                              >
                                {data.trialSearchEnJson.search}
                                <span>
                                  <IoIosArrowForward />
                                </span>
                              </button>
                            </Col>
                          ) : (
                            <Col className={'show-more-col'} sm={12}>
                              <button
                                className={'show-more-btn'}
                                hidden={!this.state.collapse}
                                type="button"
                                onClick={this.collapse}
                              >
                                {this.state.collapse
                                  ? data.trialSearchEnJson.closeDropDown
                                  : data.trialSearchEnJson.openDropDown}
                              </button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                  )}
                </Row>
              </Form>
            )}
          />
        )}
      </SimpleSearch>
    );
  }
}

FindATrial.propTypes = {
  data: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object,
  onResults: PropTypes.bool,
  inHeader: PropTypes.bool,
  searchCallBack: PropTypes.func,
  pageSearch: PropTypes.func,
};

const FindATrialQuery = props => (
  <StaticQuery
    query={graphql`
      query FindATrialQuery {
        trialSearchEnJson {
          medicalConditionModalTitle
          medicalCondition
          buttonText
          openDropDown
          closeDropDown
          distanceLabel
          postalCodeResults
          search
          searchTrials
          keywordLabel
          keywordPH
          locationLabel
          locationPH
          locationError
          gender
          age
          trialStatus
          selectMedicalCondition
          conditions
          all
          allSelected
          clear
          accept
          yes
          no
          ok
          keyowrdTooltip
          locationTooltip
          radius {
            options {
              key
              value
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          filterItems {
            masterDataName
            formikInitialValue
            label
          }
        }
      }
    `}
    render={data => <FindATrial data={data} {...props} />}
  />
);

FindATrialQuery.propTypes = {
  children: PropTypes.node,
};

export default FindATrialQuery;
