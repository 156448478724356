import React from 'react';
import { SocialCss } from './social.css';
import { StyledTooltip } from '../../global.css';
import { getCurrentLangId } from '../../constants/config';

const Socialmedia = () => {
  return (
    <SocialCss>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/pharma.bayer/"
      >
        <img
          src={'/images/icon-facebook.png'}
          alt="Facebook Icon"
          className="social-logo facebook"
        />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/bayerpharma"
      >
        <img
          src={'/images/icon-x-twitter.png'}
          alt="X Twitter Icon"
          className="social-logo x twitter"
        />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/bayerpharma"
      >
        <img
          src={'/images/icon-youtube.png'}
          alt="YouTube Icon"
          className="social-logo youtube"
        />
      </a>
      {getCurrentLangId() === 'cn' ? (
        <div className="wechat-container">
          <img
            src={'/images/icon-wechat.png'}
            alt="WeChat Icon"
            className="social-logo wechat"
            id="wechat-icon"
          />
          <StyledTooltip
            trigger="hover"
            placement="top"
            target="wechat-icon"
            className="qr-tooltip"
          >
            <img
              src={'/images/wechat-qr-code.png'}
              alt="WeChat QR Code"
              id="qr-code"
            />
            <p>{'请扫码进入公众号-拜耳临床研究'}</p>
          </StyledTooltip>
        </div>
      ) : null}
    </SocialCss>
  );
};

export default Socialmedia;
