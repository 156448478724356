import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql, navigate } from 'gatsby';
import {
  HeaderStyle,
  FindTrialDropDown,
  LangSelectorDropDown,
} from './header.css';
import Nav from 'components/nav/nav';
import Title from 'components/title';
import FindTrial from '../search/find-a-trial';
import LanguageSelector from '../language-selector/language-selector';
import { getCurrentLangId } from '../../constants/config';
import { IoIosArrowDown } from 'react-icons/io';
import Img from 'gatsby-image';

//style
import { Row, Col } from 'reactstrap';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isTrialFinderOpen: false,
      isLangSelectorOpen: false,
      selectedLanguage: 'en',
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleSubMenuOpen = this.toggleSubMenuOpen.bind(this);
    this.toggleLangSelector = this.toggleLangSelector.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedLanguage: getCurrentLangId(),
    });
  }

  toggleCollapse() {
    this.setState(state => ({
      collapse: !state.collapse,
      isLangSelectorOpen: false,
    }));
  }

  toggleDropdown() {
    this.setState(state => ({
      isTrialFinderOpen: !state.isTrialFinderOpen,
      isLangSelectorOpen: false,
    }));
  }

  toggleLangSelector() {
    this.setState(state => ({
      isLangSelectorOpen: !state.isLangSelectorOpen,
      isTrialFinderOpen: false,
    }));
  }

  toggleSubMenuOpen() {
    this.setState({
      isLangSelectorOpen: false,
      isTrialFinderOpen: false,
    });
  }

  setLanguage = lang => {
    this.setState({ selectedLanguage: lang, isLangSelectorOpen: false });
  };

  render() {
    let { data, logo, title, mainMenu, searchButton } = this.props;
    const { languages } = this.props.data.languageSelectorJson;
    return (
      <div>
        <HeaderStyle>
          <Row className={'header-row'}>
            <Col className={'header-col'}>
              <Link
                className="logo-link"
                onClick={() => {
                  window.__navigatingToLink = true;
                }}
                to="/"
              >
                {logo ? (
                  <Img
                    fluid={logo}
                    alt={title}
                    className="header-logo"
                    durationFadeIn={0}
                    loading="eager"
                  />
                ) : (
                  <Title as="h1">
                    {title || data.site.siteMetadata.siteTitle}
                  </Title>
                )}
              </Link>
              {mainMenu && mainMenu.length ? (
                <Nav
                  className={'main-menu'}
                  menuItems={data.pageMetadataJson.pages
                    .filter(page => {
                      return mainMenu.includes(page.url);
                    })
                    .sort((a, b) => {
                      return mainMenu.indexOf(a.url) - mainMenu.indexOf(b.url);
                    })}
                  menuToggle={this.toggleCollapse}
                  menuCollapseOpen={this.state.collapse}
                  languages={languages}
                  toggleLangSelector={this.toggleLangSelector}
                  isLangSelectorOpen={this.state.isLangSelectorOpen}
                  currentLang={this.state.selectedLanguage}
                  isTrialFinderOpen={this.state.isTrialFinderOpen}
                  toggleSubMenuOpen={this.toggleSubMenuOpen}
                />
              ) : null}
              <div className="find-btn">
                <button className={'last'} onClick={this.toggleDropdown}>
                  <p className={'text-button'}>{searchButton}</p>
                  <IoIosArrowDown />
                </button>
              </div>
            </Col>
          </Row>
        </HeaderStyle>
        {this.state.isTrialFinderOpen ? (
          <FindTrialDropDown>
            <div className="search-container">
              <FindTrial
                navigate={navigate}
                pageSearch={
                  typeof window !== 'undefined' &&
                  window.location.href.indexOf('/studies') > 1
                    ? () => document.location.reload()
                    : null
                }
                inHeader={true}
              />
            </div>
          </FindTrialDropDown>
        ) : null}
        {this.state.isLangSelectorOpen ? (
          <LangSelectorDropDown>
            <LanguageSelector
              languages={languages}
              setLanguage={this.setLanguage}
              activeLang={this.state.selectedLanguage}
            />
          </LangSelectorDropDown>
        ) : null}
      </div>
    );
  }
}

const HeaderWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            mainMenu
          }
        }
        languageSelectorJson {
          languages {
            internalValue
            displayValue
            icon {
              publicURL
            }
          }
        }
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
            relations
            caption
            isSubLink
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);

export default HeaderWithQuery;

Header.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  mainMenu: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.any.isRequired,
  logo: PropTypes.object.isRequired,
  searchButton: PropTypes.string,
};
