import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './nav.css';
import { StaticQuery, graphql } from 'gatsby';
import { Navbar, NavbarToggler, NavItem, Nav, Collapse } from 'reactstrap';
import { Location } from '@reach/router';
import { IoIosArrowDown } from 'react-icons/io';
import { MenuItem } from './menuItem';

class SiteNav extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onSubMenuOpen = this.onSubMenuOpen.bind(this);
    this.currentLocation = [];
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
    this.props.menuToggle();
  }

  findCurrentLangItem = () => {
    const { languages, currentLang } = this.props;
    return languages.find(elem => elem.internalValue === currentLang);
  };

  onSubMenuOpen() {
    this.props.toggleSubMenuOpen();
  }
  render() {
    const currentLangItem = this.findCurrentLangItem();
    return (
      <Container>
        <Location>
          {({ location }) => {
            let { menuItems, isLangSelectorOpen, currentLang } = this.props;
            this.currentLocation = location.pathname.split('/');

            return (
              <Navbar className={'navbar'} expand="lg">
                {menuItems.length > 2 && this.state.isOpen === false ? (
                  <NavbarToggler onClick={this.toggle}>
                    <span className={'toggle-menu-icon'} />
                  </NavbarToggler>
                ) : (
                  <NavbarToggler onClick={this.toggle}>
                    <span className={'toggle-menu-close-icon'} />
                  </NavbarToggler>
                )}
                <Collapse
                  navbar
                  isOpen={
                    menuItems.length > 2
                      ? this.state.isOpen && this.props.menuCollapseOpen
                      : true
                  }
                >
                  <Nav className="ml-auto" navbar>
                    {menuItems
                      .filter(
                        page =>
                          typeof page.isSubLink === 'undefined' ||
                          !page.isSubLink
                      )
                      .map((page, index) => (
                        <MenuItem
                          key={index}
                          index={index}
                          page={page}
                          currentLocation={this.currentLocation}
                          menuItems={menuItems}
                          isLangSelectorOpen={this.props.isLangSelectorOpen}
                          isTrialFinderOpen={this.props.isTrialFinderOpen}
                          onSubMenuOpen={this.onSubMenuOpen}
                        />
                      ))}
                    {currentLang ? (
                      <NavItem
                        className={`${
                          isLangSelectorOpen
                            ? 'lang-selector active'
                            : 'lang-selector'
                        }`}
                      >
                        <button onClick={this.props.toggleLangSelector}>
                          <img
                            src={currentLangItem.icon.publicURL}
                            draggable="false"
                            className="lang-icon"
                            alt={currentLangItem.displayValue}
                          ></img>
                          <div>
                            {currentLangItem.internalValue}
                            <IoIosArrowDown />
                          </div>
                        </button>
                      </NavItem>
                    ) : null}
                  </Nav>
                </Collapse>
              </Navbar>
            );
          }}
        </Location>
      </Container>
    );
  }
}

const NavWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
      }
    `}
    render={data => <SiteNav data={data} {...props} />}
  />
);

export default NavWithQuery;

SiteNav.propTypes = {
  data: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.any.isRequired,
  menuItems: PropTypes.array.isRequired,
  menuToggle: PropTypes.func,
  menuCollapseOpen: PropTypes.bool,
  languages: PropTypes.array,
  toggleLangSelector: PropTypes.func,
  toggleSubMenuOpen: PropTypes.func,
  isLangSelectorOpen: PropTypes.bool,
  isTrialFinderOpen: PropTypes.bool,
  currentLang: PropTypes.string,
};
