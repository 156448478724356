import styled from 'styled-components';

export const DisclaimerContainer = styled.div`
  .close-button {
    background: #00617f;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: -9px;
    z-index: 999;
    opacity: 1;
    border-radius: 50%;
    border: none;
    svg {
      color: #ffffff;
      font-size: 20px;
    }
  }
  .disclaimer-body {
    padding: 16px;
  }
  h3 {
    font-size: 48px;
    color: #10384f;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    margin: 15px 0;
    position: relative;
    padding: 0 0 10px;
    text-align: center;
    line-height: 1.2;
    ::after {
      width: 108px;
      height: 2px;
      background-color: #10384f;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -54px;
      content: ' ';
    }
  }
  .disclaimer-text {
    padding: 0 15px;
    p {
      color: #10384f;
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 16px;
    }
  }
  button {
    --webkit-appearance: none;
    background-color: transparent;
    border: 0;
    height: 28px;
    color: #00617f;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    padding: 0;
    margin-left: 15px;
    :focus {
      outline: none;
    }
  }
  .disclaimer-text {
    p:nth-child(3),
    p:nth-child(4) {
      display: none;
    }
    &.full {
      p:nth-child(3),
      p:nth-child(4) {
        display: block;
      }
    }
  }
  .disclaimer-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid #e9ecef;
    button,
    a {
      height: auto;
      color: #ffffff;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 1.5;
      border-radius: 4px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-color: #00617f;
      border: solid 1px #00617f;
      :hover {
        background-color: #0069d9;
        border-color: #0062cc;
        text-decoration: none;
      }
    }
    button {
      margin-left: 0;
      margin-right: 5px;
      font-weight: normal;
    }
  }
  @media screen and (max-width: 767px) {
    h3 {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 420px) {
    h3 {
      font-size: 26px;
    }
  }
`;
