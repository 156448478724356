import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { white } from '../../constants/theme';

export const HeaderFixed = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  background-color: ${white};
  top: 0;
  box-shadow: 0 1px 3px rgba(1, 1, 1, 0.1);
`;
export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CustomSpinner = styled(Spinner)`
  color: #0075a6;
  width: 45px;
  height: 45px;
`;
