import React from 'react';
import { Helmet } from 'react-helmet';
import { cookieBannerToken } from '../../constants/config';

const CookieBanner = () => {
  const isBrowser = typeof window !== 'undefined';
  const bannerToken = cookieBannerToken();

  if (!isBrowser) {
    return null;
  }

  return (
    <Helmet>
      <script
        id="cookie-banner"
        type="text/javascript"
        src={`https://cdn.cookielaw.org/consent/${bannerToken}/OtAutoBlock.js`}
      />
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script={bannerToken}
      />
      <script type="text/javascript">function OptanonWrapper(){}</script>
    </Helmet>
  );
};

export default CookieBanner;
