import styled from 'styled-components';
import { white, purple } from 'constants/theme';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: ${purple};
  padding: 25px 0;
  font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
  .social-holder {
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 14px;
      color: white;
    }
  }
  .nav-holder {
    display: flex;
    flex-direction: row;
  }
  .aut-copy {
    text-align: left;
    color: ${white};
    font-size: 14px;
    line-height: 1.5;
  }
  .menu-links {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    align-items: top;
    font-size: 14px;
    line-height: 1.5;
    color: ${white};
    .align-links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
    a {
      margin: 0 5px;
      color: ${white};
    }
  }
  .powered {
    font-size: 14px;
    line-height: 1.5;
    color: ${white};
    a {
      margin: 0 5px;
      color: ${white};
    }
    img {
      max-height: 56px;
    }
    p {
      margin-right: 8px;
      margin-bottom: 0px;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
    }
  }
  @media screen and (max-width: 991px) {
    .nav-holder {
      flex-direction: column;
    }
    .aut-copy {
      text-align: center;
    }
    .powered {
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    .powered {
      margin-bottom: 40px;
    }
  }
  @media print {
    a {
      text-decoration: none;
    }
  }
`;
