import styled from 'styled-components';
import { DialogContent, DialogOverlay } from '@reach/dialog';

export const StyledDialogOverlay = styled(DialogOverlay)`
  background: none !important;
  padding: 0 !important;
  z-index: 9999;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 0.15s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &.view-results,
  &.all-conditions {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
  &.all-conditions {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  animation-name: comeFromTop;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;

  @keyframes comeFromTop {
    0% {
      transform: translate(0, -25%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  width: 100% !important;
  padding: 0 !important;
  position: relative;
  &.conditions {
    max-width: 920px;
    margin: 28px auto;
    border: solid 2px #6a4c72;
  }
  &.ask-to-join,
  &.additional-questions,
  &.contact-us {
    max-width: 800px;
    border: solid 1px #10384f;
    margin: 28px auto;
  }
  &.redirect {
    max-width: 500px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4.8px;
    margin: 28px auto;
  }
  &.criteria {
    max-width: 920px;
    border: solid 1px #10384f;
    margin: 28px auto;
  }
  &.disclaimer {
    max-width: 500px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4.8px;
    margin: 28px auto;
  }
  &.view-results {
    max-width: 800px;
    border: solid 1px #cccccc;
    border-radius: 6px;
    margin: 0;
  }
  &.all-conditions {
    max-width: 800px;
    margin: 0;
    max-height: calc(100vh - 16px);
    overflow: auto;
  }
  @media screen and (max-width: 991px) {
    &.ask-to-join,
    &.additional-questions,
    &.contact-us {
      max-width: 500px;
    }
    &.criteria {
      max-width: 92%;
    }
    &.view-results {
      max-width: calc(100% - 30px);
    }
  }
  @media screen and (max-width: 575px) {
    &.conditions,
    &.ask-to-join,
    &.additional-questions,
    &.contact-us,
    &.disclaimer,
    &.redirect {
      max-width: 96%;
      margin: 8px auto;
    }
    &.criteria {
      margin: 15px auto;
    }
    &.view-results {
      margin-top: 30px;
    }
  }
`;

export const Button = styled.button`
  -webkit-appearance: none;
  background-color: #ffffff;
  text-align: left;
  :focus {
    outline: none;
  }
`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-weight: normal;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  height: 49px;
  display: none;
  :focus {
    outline: none;
  }
`;
