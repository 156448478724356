import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import { Location } from '@reach/router';
import { HeaderFixed } from './layout.css';
import Disclaimer from '../disclaimer/disclaimer';
import CookieBanner from '../cookie-banner/CookieBanner';
import useNewRelic from './newrelic';

const Layout = ({ data, children, meta }) => {
  useNewRelic();

  return (
    <div>
      <GlobalStyle />
      <CookieBanner />
      <Head {...meta} />
      <Location>
        {({ location }) => (
          <div className={'website-container'}>
            <HeaderFixed>
              <Header
                title={data.site.siteMetadata.siteTitle}
                langs={data.site.siteMetadata.languages.langs}
                selectedLanguage={
                  location.pathname.split('/')[1] === '404'
                    ? data.site.siteMetadata.languages.defaultLangKey
                    : location.pathname.split('/')[1] ||
                      data.site.siteMetadata.languages.defaultLangKey
                }
                teasureImage={data.headerJson.gallery.image}
                logo={data.headerJson.logo.childImageSharp.fluid}
                pageValidation={data.site.siteMetadata.mainMenu}
                mainMenu={data.site.siteMetadata.mainMenu}
                searchButton={data.headerJson.searchButton}
              />
            </HeaderFixed>

            {children}
            <Disclaimer />
            <Footer
              footerMenu={data.site.siteMetadata.footerMenu}
              langs={data.site.siteMetadata.languages.langs}
              selectedLanguage={
                location.pathname.split('/')[1] === '404'
                  ? data.site.siteMetadata.languages.defaultLangKey
                  : location.pathname.split('/')[1] ||
                    data.site.siteMetadata.languages.defaultLangKey
              }
            />
          </div>
        )}
      </Location>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
            mainMenu
            footerMenu
            languages {
              defaultLangKey
              langs
              defaultPageUrl
            }
          }
        }
        headerJson {
          siteTitle
          searchButton
          gallery {
            image {
              childImageSharp {
                fluid(maxHeight: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          logo {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
};

export default LayoutWithQuery;
