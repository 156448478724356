import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Label } from 'reactstrap';

const Checkbox = ({ label, value, fieldName, childrenCond }) => {
  const onChange = (field, form) => {
    if (field.value && field.value.includes(value)) {
      let nextValue = field.value.filter(oldVal => oldVal !== value);
      if (childrenCond) {
        childrenCond.forEach(child => {
          if (field.value.includes(child)) {
            nextValue = nextValue.filter(oldVal => oldVal !== child);
          }
        });
      }
      form.setFieldValue(fieldName, nextValue);
    } else {
      let nextValue = field.value.concat(value);
      if (childrenCond) {
        childrenCond.forEach(child => {
          nextValue = field.value.includes(child)
            ? nextValue
            : nextValue.concat(child);
        });
      }
      form.setFieldValue(fieldName, nextValue);
    }
  };

  const idSuffix = Math.floor(Math.random(400) * 10000);

  return (
    <Field name={fieldName}>
      {({ field, form }) => {
        return (
          <Label htmlFor={value + idSuffix}>
            {label ? label : null}
            <input
              type="checkbox"
              checked={field.value && field.value.includes(value)}
              onChange={() => onChange(field, form)}
              id={value + idSuffix}
            />
            <span className="checkmark" />
          </Label>
        );
      }}
    </Field>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  childrenCond: PropTypes.array,
};

export default Checkbox;
