import styled from 'styled-components';

export const LanguageSelectorStyle = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  position: relative;
  .lang-icon {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    margin-bottom: 6px;
    object-fit: cover;
    &.active {
      box-shadow: 0 0 10px 0 #76cd19;
    }
  }
  .lang-container {
    width: fit-content;
    margin: 0 auto;
    a,
    button {
      -webkit-appearance: none;
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 18px 0;
      font-size: 12px;
      line-height: 14px;
      color: #555555;
      :hover {
        color: #555555;
        text-decoration: none;
      }
      :focus {
        outline: none;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    max-width: calc(100% - 80px);
  }
  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
`;

export const CustomArrows = styled.div`
  button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    font-size: 35px;
    position: absolute;
    padding: 0;
    top: 17px;
    z-index: 2;
    color: #10384f;
    :focus {
      outline: none;
    }
  }
  button:first-child {
    left: -40px;
  }
  button:last-child {
    right: -40px;
  }
  @media screen and (max-width: 1200px) {
    button:first-child {
      left: -30px;
    }
    button:last-child {
      right: -30px;
    }
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
