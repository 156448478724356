import styled from 'styled-components';
import { textActive, textGrey } from '../../constants/theme';

export const Container = styled.nav`
  width: 100%;
  .navbar {
    min-height: 84px;
    justify-content: center;
    padding: 0;
    .navbar-toggler {
      height: 84px;
      .toggle-menu-icon {
        display: inline-block;
        height: 30px;
        width: 30px;
        border: none;
        padding: 0;
        background: url(/images/togglemenu.svg) no-repeat center center;
        background-size: 100% 100%;
      }
      .toggle-menu-close-icon {
        display: inline-block;
        height: 30px;
        width: 30px;
        border: none;
        padding: 0;
        background: url(/images/togglemenu-close.svg) no-repeat center center;
        background-size: 100% 100%;
      }
      :focus {
        outline: none;
      }
    }
    button {
      padding: 0;
    }
    .navbar-collapse {
      margin: 0 -15px;
      @media all and (-ms-high-contrast: none) {
        height: 84px;
      }
      .ml-auto {
        height: 100%;
        align-items: center;
        li:nth-child(6) {
          @media screen and (min-width: 992px) {
            padding-right: 275px;
          }
        }
        .nav-item {
          list-style: none;
          &.relative {
            position: relative;
          }
          .nav-link {
            &.home {
              padding-right: 20px;
            }
            &.with-dropdown {
              display: flex;
              align-items: center;
              svg {
                display: inline-block;
                font-size: 24px;
              }
            }
          }
          .nav-menu-0 {
            max-width: 115px;
          }
          .nav-menu-1 {
            max-width: 115px;
          }
          .nav-menu-2 {
            max-width: 145px;
          }
          .nav-menu-3 {
            max-width: 100px;
          }
          .nav-menu-4,
          .redirect-modal-btn {
            max-width: 135px;
            background-color: transparent;
            outline: none;
            border: none;
          }
          .nav-menu-5 {
            max-width: 140px;
          }
          .redirect-modal-btn {
            background: transparent;
            border: none;
          }
          .nav-link,
          .redirect-modal-btn {
            font-size: 14px;
            line-height: 1;
            text-align: center;
            color: ${textGrey};
            font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
            text-transform: uppercase;
            position: relative;
            padding: 0 10px;
            display: table-cell;
            vertical-align: middle;
            text-decoration: none;
            &.home {
              font-size: 0;
              span {
                font-size: 14px;
                padding: 7px 15px;
                background: url(/images/home-icon1_2_new.png) no-repeat center
                  center;
                :hover {
                  background: url(/images/home-icon1_new.png) no-repeat center
                    center;
                }
              }
              .active {
                background: url(/images/home-icon1_new.png) no-repeat center
                  center;
              }
            }
            &:after {
              content: ' ';
              background: url(/images/menu-line.png) no-repeat center center;
              width: 6px;
              display: block;
              height: 27px;
              text-transform: uppercase;
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -13px;
            }
            &:hover {
              outline: none;
              color: ${textActive};
              font-weight: 700;
            }
            svg {
              display: none;
            }
            &.transparency-policy {
              &:after {
                content: ' ';
                background: none;
              }
            }
          }
          .active {
            color: ${textActive};
            font-weight: 700;
          }
        }
      }
    }
  }
  @media (max-width: 1100px) and (min-width: 992px) {
    .navbar {
      li:nth-child(6) {
        padding-right: 265px !important;
      }
      .nav-item {
        .nav-menu-0 {
          max-width: 95px !important;
        }
        .nav-menu-1 {
          max-width: 95px !important;
        }
        .nav-menu-2 {
          max-width: 95px !important;
        }
        .nav-menu-3 {
          max-width: 85px !important;
        }
        .nav-menu-4,
        .redirect-modal-btn {
          max-width: 90px !important;
        }
        .nav-menu-5 {
          max-width: 120px !important;
        }
        .nav-link,
        .redirect-modal-btn {
          font-size: 12px !important;
          padding: 0 10px !important;
          &.home {
            font-size: 0 !important;
          }
        }
      }
    }
  }
  //STYLE Mobile and Tablet
  @media screen and (max-width: 991px) {
    .navbar {
      justify-content: flex-end;
      .navbar-toggler {
        padding: 0 11px;
      }
      .navbar-collapse {
        .ml-auto {
          padding: 20px 15px 30px;
          background-color: #10384f;
          max-width: 750px;
          margin: 0 auto;
          li:nth-child(6) {
            padding-right: 0;
          }
          .nav-item {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            position: relative;
            width: 100%;
            .redirect-modal-btn {
              width: 100%;
            }
            .nav-link,
            .redirect-modal-btn,
            button {
              color: #ffffff !important;
              text-align: left;
              text-transform: uppercase;
              font-size: 14px;
              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
              max-width: unset !important;
              display: flex;
              padding: 15px 12px;
              ::after {
                background: url(/images/menu-mobile-arrow.png) no-repeat center
                  center;
                width: 11px;
                height: 20px;
                content: '';
                position: absolute;
                top: 50%;
                right: 6px;
                margin-top: -10px;
              }
              &.home {
                font-size: 14px;
              }
              &.transparency-policy {
                &:after {
                  background: url(/images/menu-mobile-arrow.png) no-repeat
                    center center;
                  width: 11px;
                  height: 20px;
                  content: '';
                  position: absolute;
                  top: 50%;
                  right: 6px;
                  margin-top: -10px;
                }
              }
              &.active {
                font-weight: 400;
              }
            }
            .home-icon {
              display: none;
            }
          }
          .sub-menu-item {
            .redirect-modal-btn {
              color: #275f7c !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 570px) {
    .navbar .navbar-collapse {
      margin: 0;
    }
  }
  .sub-menu {
    position: absolute;
    flex-direction: column;
    top: calc(100% + 28px);
    background-color: transparent;
    padding-bottom: 11px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
    z-index: 4;
    background-color: #fbfbfb;
    left: 50%;
    transform: translateX(-50%);
    min-width: 300px;
    display: none;
    &.open {
      display: block;
    }
    .sub-menu-item {
      margin: 11px 11px 0 11px;
      padding-bottom: 11px;
      border-bottom: 1px solid rgba(85, 85, 85, 0.3);
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
      .caption {
        width: 100%;
        color: #555555;
        text-align: left;
        margin-top: 4px;
      }
      .redirect-modal-btn {
        max-width: none !important;
        text-transform: none !important;
        padding: 0 !important;
        margin: 0 !important;
        color: #275f7c !important;
        width: 100% !important;
        text-align: left !important;
        max-width: none !important;
        &:hover {
          text-decoration: underline !important;
        }
        &::after {
          content: none !important;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .sub-menu {
      position: relative;
      .sub-menu-item {
        margin-top: 0;
        padding-top: 11px;
        button {
          color: #275f7c !important;
        }
      }
    }
    .nav-link {
      &.with-dropdown {
        width: 100%;
        justify-content: space-between;
        padding-right: 0 !important;
        &::after {
          content: none !important;
        }
      }
    }
  }
`;
